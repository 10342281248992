import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { AppLayout } from '@common/components';
import { CheckInLogo } from './logo';

const useStyles = makeStyles((theme) =>
	createStyles({
		mainLayout: {
			display: 'grid',
			justifyItems: 'center',
			gridTemplateRows: 'auto 1fr',
			padding: theme.spacing(0),
			paddingBottom: theme.spacing(2),
		},
		header: {
			display: 'grid',
			justifySelf: 'stretch',
			placeContent: 'center',
			padding: theme.spacing(4, 0),
			backgroundColor: '#F8F8F8',

			[theme.breakpoints.up('sm')]: {
				paddingBottom: theme.spacing(4),
			},
		},
		content: {
			width: '100%',
			background: `linear-gradient(to bottom, #F8F8F8, #F8F8F8 5%, white 95%);`,
		},
	}),
);

export const LandingLayout: React.ComponentType = ({ children }) => {
	const styles = useStyles();
	return (
		<AppLayout mainContentClassName={styles.mainLayout}>
			<div className={styles.header}>
				<CheckInLogo />
			</div>
			<div className={styles.content}>{children}</div>
		</AppLayout>
	);
};
