import { NobleCard } from '../../../components';
import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import type {
	CheckinImageCard,
	CheckinUserState,
	CheckinWordCard,
} from '@contracts/checkin';
import { isImageCardType, isWordCardType } from '@contracts/checkin';
import type { UserPresence } from '@contracts/activity-presence';
import clsx from 'clsx';

const selectedImage = {
	xs: { width: '233px', height: '150px' },
	md: { width: '379px', height: '246px' },
};

const useStyles = makeStyles((theme) => {
	return createStyles({
		cards: {
			display: 'grid',
			alignSelf: 'center',
			justifyContent: 'center',
			alignContent: 'center',
			margin: theme.spacing(2, 0, 8),

			[theme.breakpoints.up('sm')]: {
				margin: theme.spacing(4, 0, 8),
			},

			[theme.breakpoints.up('md')]: {
				justifyContent: 'flex-start',
				margin: theme.spacing(4, 0),
			},
		},
		cardsContainer: {
			position: 'relative',
			...selectedImage.xs,

			[theme.breakpoints.up('md')]: {
				...selectedImage.md,
			},
		},
		card: {
			...selectedImage.xs,

			[theme.breakpoints.up('md')]: {
				...selectedImage.md,
			},
		},
		wordCard: {
			position: 'absolute',
			right: '-10%',
			bottom: '-15%',
			transform: 'rotate(1.81deg)',
		},
		imageCard: {
			position: 'absolute',
			top: '-15%',
			left: '-10%',
			transform: 'rotate(-6.85deg)',
		},
	});
});

type State = {
	imageCard?: CheckinImageCard;
	currentPresentedWordCard?: CheckinWordCard;
};

type Props = {
	cards: (CheckinImageCard | CheckinWordCard)[];
	currentPresenter: UserPresence;
	participantsState: CheckinUserState[];
};

const useCards = ({
	cards,
	currentPresenter,
	participantsState,
}: Props): State => {
	const participantState = participantsState.find(
		(participantState) => participantState.uid === currentPresenter.uid,
	);
	const imageCard = cards
		.filter(isImageCardType)
		.find((card) => card.checkinCardId === participantState?.imageCardId);
	const currentPresentedWordCard = cards
		.filter(isWordCardType)
		.find((card) => card.checkinCardId === participantState?.wordCardId);

	return {
		imageCard,
		currentPresentedWordCard,
	};
};

export const Cards: React.ComponentType<Props> = ({
	cards,
	currentPresenter,
	participantsState,
}) => {
	const { imageCard, currentPresentedWordCard } = useCards({
		cards,
		currentPresenter,
		participantsState,
	});
	const styles = useStyles();

	return (
		<div className={styles.cards}>
			<div className={styles.cardsContainer}>
				{currentPresentedWordCard && (
					<NobleCard
						key={currentPresentedWordCard.checkinCardId}
						className={clsx(styles.card, styles.wordCard)}
						card={currentPresentedWordCard}
						textAlignment="right-bottom"
					/>
				)}
				{imageCard && (
					<NobleCard
						key={imageCard.checkinCardId}
						className={clsx(styles.card, styles.imageCard)}
						card={imageCard}
					/>
				)}
			</div>
		</div>
	);
};
