import { Theme, useMediaQuery } from '@material-ui/core';

type State = {
	titleVariant: 'h5' | 'h1';
};

export const useHeader = (): State => {
	const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
	const titleVariant = isXs ? 'h5' : 'h1';

	return {
		titleVariant,
	};
};
