import { createStyles, makeStyles } from '@material-ui/core';
import { NobleCard, User } from '../../../components';
import React from 'react';
import type { UserPresence } from '@contracts/activity-presence';
import type { UserStatus } from '../../../store/getUsersStatus';
import type {
	CheckinActivityStage,
	CheckinImageCard,
	CheckinUserState,
	CheckinWordCard,
} from '@contracts/checkin';
import clsx from 'clsx';

const selectedImage = {
	xs: { width: '134px', height: '86px' },
	md: { width: '195px', height: '126px' },
};

const useStyles = makeStyles((theme) => {
	return createStyles({
		userCard: {
			display: 'grid',
			gridTemplateColumns: 'repeat(2, auto)',
			justifySelf: 'center',
			gap: theme.spacing(2),
		},
		userCardUser: {
			alignSelf: 'center',
			justifySelf: 'flex-end',
			marginRight: theme.spacing(5),
		},
		cards: {
			position: 'relative',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			...selectedImage.xs,

			[theme.breakpoints.up('md')]: {
				...selectedImage.md,
			},
		},
		card: {
			position: 'absolute',
			...selectedImage.xs,

			[theme.breakpoints.up('md')]: {
				...selectedImage.md,
			},
		},
		wordCard: {
			right: '-10%',
			bottom: '-15%',
			transform: 'rotate(1.81deg)',
		},
		imageCard: {
			top: '-15%',
			left: '-10%',
			transform: 'rotate(-6.85deg)',
		},
	});
});

type Props = {
	avatarSize: number;
	activityStage: CheckinActivityStage;
	userPresence: UserPresence;
	userStatus: UserStatus;
	participantState: CheckinUserState;
	imageCard?: CheckinImageCard;
	wordCard?: CheckinWordCard;
};

export const UserCard: React.ComponentType<Props> = ({
	activityStage,
	avatarSize,
	participantState,
	userPresence,
	userStatus,
	imageCard,
	wordCard,
}) => {
	const styles = useStyles();
	const isHost = participantState.role === 'host';

	return (
		<div className={styles.userCard}>
			<User
				activityStage={activityStage}
				isHost={isHost}
				className={styles.userCardUser}
				imageSize={avatarSize}
				displayName={userPresence.displayName}
				photoURL={userPresence.photoURL}
				connected={userPresence.connected}
				userStatus={userStatus}
			/>
			<div className={styles.cards}>
				{wordCard && (
					<NobleCard
						key={wordCard.checkinCardId}
						className={clsx(styles.card, styles.wordCard)}
						card={wordCard}
						textAlignment="right-bottom"
						variant="sm"
					/>
				)}
				{imageCard && (
					<NobleCard
						key={imageCard.checkinCardId}
						className={clsx(styles.card, styles.imageCard)}
						card={imageCard}
					/>
				)}
			</div>
		</div>
	);
};
