import React from 'react';
import { Container, createStyles, makeStyles } from '@material-ui/core';
import { ErrorSnackbar, LoadingButton } from '@common';
import type { UserPresence } from '@contracts/activity-presence';
import type { CheckinActivity, CheckinUserState } from '@contracts/checkin';
import type { UsersStatus } from '../../../store/getUsersStatus';
import clsx from 'clsx';
import { usePresenting } from './state';
import { HostActions } from './hostActions';
import { Presenter } from './presenter';
import { Cards } from './cards';

const useStyles = makeStyles((theme) => {
	return createStyles({
		presenting: {
			display: 'grid',
			gridTemplateAreas: `
				'cards'
				'presenter'
				'presenter-actions'
				'host-actions'
			`,
			alignContent: 'start',
			gap: theme.spacing(2),
			padding: theme.spacing(6, 4),

			[theme.breakpoints.up('md')]: {
				gridTemplateAreas: `
					'presenter cards'
					'presenter-actions presenter-actions'
					'host-actions host-actions'
				`,
				padding: theme.spacing(6, 0),
				gap: theme.spacing(6),
			},
		},
		cards: {
			gridArea: 'cards',
		},
		presenter: {
			gridArea: 'presenter',
		},
		presenterActions: {
			gridArea: 'presenter-actions',

			justifySelf: 'center',
		},
		hostActions: {
			gridArea: 'host-actions',
		},
	});
});

type Props = {
	activity: CheckinActivity;
	usersStatus: UsersStatus;
	currentUserUid: string;
	participantsState: CheckinUserState[];
	currentPresenter: UserPresence;
	nextPresenter?: UserPresence;
	className?: string;
};

export const Presenting: React.ComponentType<Props> = ({
	activity,
	usersStatus,
	currentUserUid,
	currentPresenter,
	nextPresenter,
	participantsState,
	className,
}) => {
	const { activityId, cards } = activity;
	const {
		userStatus,
		showPresenterActions,
		showHostActions,
		onFinishTurn,
		isFinishTurnPending,
		error,
	} = usePresenting({
		activityId,
		participantsState: participantsState,
		usersStatus,
		currentPresenter,
		currentUserUid,
	});
	const styles = useStyles();

	return (
		<Container maxWidth="md" className={clsx(styles.presenting, className)}>
			{error && (
				<ErrorSnackbar
					error="Sorry! We could not finish your turn, please try again."
					extraErrorInfo={error?.message}
				/>
			)}
			<div className={styles.cards}>
				<Cards
					cards={cards}
					currentPresenter={currentPresenter}
					participantsState={participantsState}
				/>
			</div>
			<div className={styles.presenter}>
				{userStatus && (
					<Presenter
						activity={activity}
						participantsState={participantsState}
						currentPresenter={currentPresenter}
						nextPresenter={nextPresenter}
						userStatus={userStatus}
					/>
				)}
			</div>
			{showPresenterActions && (
				<div className={styles.presenterActions}>
					<LoadingButton
						variant="contained"
						color="primary"
						onClick={onFinishTurn}
						loading={isFinishTurnPending}
						disabled={isFinishTurnPending}
					>
						Finish turn
					</LoadingButton>
				</div>
			)}
			{showHostActions && (
				<HostActions
					activityId={activityId}
					currentUserUid={currentUserUid}
					participantsState={participantsState}
					nextPresenter={nextPresenter}
					className={styles.hostActions}
				/>
			)}
		</Container>
	);
};
