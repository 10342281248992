import React from 'react';
import { BaseProviders } from '@remote-social/common/src';
import { appConfig } from '../appConfig';
import { store } from './reduxStore';
import { CheckInStoreProvider } from './checkinStore';
import { createCheckinTheme } from '../checkinTheme';

const checkinTheme = createCheckinTheme();

export const AppProvider: React.ComponentType = ({ children }) => (
	<BaseProviders store={store} appConfig={appConfig} theme={checkinTheme}>
		<CheckInStoreProvider>{children}</CheckInStoreProvider>
	</BaseProviders>
);
