import type { CheckinActivity, CheckinUserState } from '@contracts/checkin';

export type UserStatus =
	| 'pending'
	| 'spectating'
	| 'selecting-image-card'
	| 'selected-image-card'
	| 'selecting-word-card'
	| 'selected-word-card'
	| 'presenting-presenter'
	| 'presenting-listener';

export type UsersStatus = { [uid: string]: UserStatus };

type Props = {
	activity: CheckinActivity;
	participantsState: CheckinUserState[];
	currentPresenterUid?: string;
};

export const getUsersStatus = ({
	activity,
	participantsState,
	currentPresenterUid,
}: Props): UsersStatus => {
	const selectedImageCardCount = participantsState.filter(
		(participantState) => participantState.imageCardId,
	).length;

	const selectedWordCardCount = participantsState.filter(
		(participantState) => participantState.wordCardId,
	).length;

	const participatingUserCount = participantsState.length;
	const uids = activity.uids;
	const activityStage = activity.activityStage;

	const usersStatus = uids.reduce<UsersStatus>((result, uid) => {
		const participantState = participantsState.find(
			(participantState) => participantState.uid === uid,
		);

		if (uids.includes(uid) && !participantState) {
			result[uid] = 'spectating';
		} else if (
			activityStage === 'image-card-select' &&
			selectedImageCardCount < participatingUserCount
		) {
			const userImageCardId = participantState?.imageCardId;
			if (userImageCardId) {
				result[uid] = 'selected-image-card';
			} else {
				result[uid] = 'selecting-image-card';
			}
		} else if (
			activityStage === 'word-card-select' &&
			selectedWordCardCount < participatingUserCount
		) {
			const userWordCardId = participantState?.wordCardId;
			if (userWordCardId) {
				result[uid] = 'selected-word-card';
			} else {
				result[uid] = 'selecting-word-card';
			}
		} else if (activityStage === 'presenting') {
			result[uid] =
				uid === currentPresenterUid
					? 'presenting-presenter'
					: 'presenting-listener';
		} else {
			result[uid] = 'pending';
		}

		return result;
	}, {});

	return usersStatus;
};
