import { createTheme } from '@material-ui/core';
import { createRemoteSocialTheme } from '@common/styles';
import { HslaColor, makeHSLA } from '@common/utils/colors';

const checkInGreen = { h: 162, s: 100, l: 34, a: 1 };
const offWhite = { h: 255, s: 0, l: 100, a: 1 };
const cardColorBases: { [color: string]: HslaColor } = {
	yellow: { h: 46.8, s: 91.3, l: 50.4, a: 1 },
	green: { h: 104.9, s: 92.3, l: 30.4, a: 1 },
	orange: { h: 24, s: 94.1, l: 53.1, a: 1 },
	blue: { h: 206, s: 97.1, l: 41, a: 1 },
	magenta: { h: 338.5, s: 71.4, l: 54.7, a: 1 },
	purple: { h: 269.8, s: 50.6, l: 48.4, a: 1 },
	gray: { h: 0, s: 96.1, l: 0, a: 1 },
};

export type CardColors = {
	[color: keyof typeof cardColorBases]: {
		backgroundColor: string;
		color: string;
	};
};

declare module '@material-ui/core/styles/createPalette' {
	export interface Palette {
		cards: CardColors;
	}
}

const cards = Object.keys(cardColorBases).reduce<CardColors>((cards, key) => {
	const cardColor = cardColorBases[key];

	if (!cardColor) {
		return cards;
	}

	cards[key] = {
		backgroundColor: makeHSLA({
			...cardColor,
			s: cardColor.s * 3,
			a: 0.15,
		}),
		color: makeHSLA({
			...cardColor,
			s: cardColor.s / 1.3,
			a: 1,
		}),
	};

	return cards;
}, {});

export const createCheckinTheme = () => {
	return createTheme(
		createRemoteSocialTheme({
			name: 'checkin',
			createPalette: () => ({
				type: 'light',
				primary: {
					main: makeHSLA(checkInGreen),
					contrastText: makeHSLA(offWhite),
				},
				background: {
					default: '#fff',
				},
				cards,
			}),
		}),
	);
};
