import { UserStatus } from '../../../store/getUsersStatus';
import BannerSpectator from '../../../assets/banner-spectator.png';
import BannerSelectImageCard from '../../../assets/banner-select-image-card.png';
import BannerSelectWordCard from '../../../assets/banner-select-word-card.png';
import BannerPresentingPresent from '../../../assets/banner-presenting-present.png';
import { UnreachableError } from '@common';

type State = {
	hidden: boolean;
	imgSrc: string;
	title: string;
	body: string;
};

type Props = {
	userStatus: UserStatus;
};

export const useBanner = ({ userStatus }: Props): State => {
	let hidden = true;
	let imgSrc = '';
	let title = '';
	let body = '';

	switch (userStatus) {
		case 'spectating':
			hidden = false;
			imgSrc = BannerSpectator;
			title = 'You are now spectating Check-in';
			body =
				'Check-in has already progressed too far to join, you\n' +
				'are now spectating. To join ask the host to restart\n' +
				'Check-in.';
			break;
		case 'selecting-image-card':
		case 'selected-image-card':
			hidden = false;
			imgSrc = BannerSelectImageCard;
			title = 'Pick an image card';
			body =
				'Start by picking an image card that best supports how you are feeling today.';
			break;
		case 'selecting-word-card':
		case 'selected-word-card':
			hidden = false;
			imgSrc = BannerSelectWordCard;
			title = 'Pick a word card';
			body =
				'Choose a word that best conveys your intention for the week.';
			break;
		case 'presenting-presenter':
			hidden = false;
			imgSrc = BannerPresentingPresent;
			title = 'Express yourself';
			body =
				'Share why these cards reflect how you feel and your intention for the week.';
			break;
		case 'presenting-listener':
			hidden = false;
			imgSrc = BannerPresentingPresent;
			title = 'Wait for your turn and listen to your peers';
			body =
				'Everyone will take a minute to share how they are feeling today and their intention for the week.';
			break;
		case 'pending':
			break;
		default:
			throw new UnreachableError(userStatus);
	}

	return {
		hidden,
		imgSrc,
		title,
		body,
	};
};
