import { User } from '../../../components';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import type { CheckinActivity, CheckinUserState } from '@contracts/checkin';
import type { UserPresence } from '@contracts/activity-presence';
import { CountdownIndicator } from '@common';
import type { UserStatus } from '../../../store/getUsersStatus';

const useStyles = makeStyles((theme) => {
	return createStyles({
		presenter: {
			display: 'grid',
			gridAutoFlow: 'column',
			gridTemplateColumns: 'min-content',
			justifyContent: 'center',
			gap: theme.spacing(4),

			[theme.breakpoints.up('md')]: {
				gridAutoFlow: 'row',
				gridTemplateColumns: 'auto',
				alignContent: 'center',
				height: '100%',
			},
		},
		presenterUser: {
			[theme.breakpoints.up('md')]: {
				margin: 0,
			},
		},
		presenterInfo: {
			[theme.breakpoints.up('md')]: {
				alignItems: 'center',
			},
		},
	});
});

type State = {
	imageSize: number;
	presenterCountdownMs: number;
	participantState?: CheckinUserState;
};

type Props = {
	activity: CheckinActivity;
	participantsState: CheckinUserState[];
	currentPresenter: UserPresence;
	nextPresenter?: UserPresence;
	userStatus: UserStatus;
};

const usePresenter = ({
	currentPresenter,
	activity,
	participantsState,
}: Pick<
	Props,
	'activity' | 'currentPresenter' | 'participantsState'
>): State => {
	const defaultPresenterCountdownMs = 90000;
	const [presenterCountdownMs, setPresenterCountdownMs] = React.useState(
		defaultPresenterCountdownMs,
	);

	const imageSize = 60;

	const participantState = participantsState.find(
		(participantState) => participantState.uid === currentPresenter.uid,
	);

	const presenters = activity.presenters;

	React.useEffect(() => {
		// forces a refresh of countdown timer
		const presenterIndex = presenters.indexOf(currentPresenter.uid);
		setPresenterCountdownMs(defaultPresenterCountdownMs + presenterIndex);
	}, [currentPresenter, presenters]);

	const result = React.useMemo(
		() => ({
			imageSize,
			presenterCountdownMs,
			participantState,
		}),
		[participantState, presenterCountdownMs],
	);

	return result;
};

export const Presenter: React.ComponentType<Props> = ({
	activity,
	participantsState,
	currentPresenter,
	nextPresenter,
	userStatus,
}) => {
	const { imageSize, presenterCountdownMs, participantState } = usePresenter({
		activity,
		currentPresenter,
		participantsState,
	});
	const styles = useStyles();

	const activityStage = activity.activityStage;
	const isHost = participantState?.role === 'host' || false;

	return (
		<div className={styles.presenter}>
			<User
				activityStage={activityStage}
				userStatus={userStatus}
				isHost={isHost}
				className={styles.presenterUser}
				displayName={currentPresenter?.displayName}
				connected={currentPresenter?.connected}
				photoURL={currentPresenter?.photoURL}
				shouldShowRole={false}
				imageSize={imageSize}
			/>
			<div className={styles.presenterInfo}>
				<CountdownIndicator
					durationMs={presenterCountdownMs}
					size={imageSize}
				/>
				{nextPresenter && (
					<Typography variant="h6">
						Up next:
						<br />
						{nextPresenter.displayName}
					</Typography>
				)}
			</div>
		</div>
	);
};
