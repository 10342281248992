import React from 'react';
import { useState } from './state';
import {
	CopyText,
	DocumentHead,
	LinkButton,
	Loading,
	PageError,
} from '@common';
import { LocationDescriptor } from 'history';
import {
	Container,
	createStyles,
	makeStyles,
	Theme,
	Typography,
} from '@material-ui/core';
import { routes } from '../../routes';
import { CheckinTile } from '../../components/checkinTile';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		roomRoom: {
			backgroundColor: theme.palette.background.default,
			padding: theme.spacing(2, 0),

			[theme.breakpoints.up('sm')]: {
				padding: theme.spacing(4, 0),
			},
		},
		container: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(4),

			[theme.breakpoints.up('md')]: {
				flexDirection: 'row',
			},
		},
		actions: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			flexBasis: 0,
			minWidth: 0,
		},
		cta: {
			marginBottom: theme.spacing(2),
		},
		activities: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			flexBasis: 0,
			minWidth: 0,
			gap: theme.spacing(2),
		},
		list: {
			display: 'grid',
			gap: theme.spacing(2, 0),
		},
	}),
);

export const Room: React.ComponentType = () => {
	const state = useState();
	const styles = useStyles();

	if (state.status === 'error' && state.error) {
		return <PageError error={state.error} />;
	}

	if (state.status === 'pending' || state.status !== 'success') {
		return <Loading showRandomLabels />;
	}

	const {
		data: {
			url,
			room: { roomId, accountId, schedule },
			activities: { current, previous },
		},
	} = state;
	const createActivityLocation: LocationDescriptor = {
		pathname: routes.createActivity.path,
		state: {
			roomId,
			accountId,
		},
	};

	return (
		<div className={styles.roomRoom}>
			<DocumentHead title="Room" />
			<Container fixed maxWidth="md">
				<div className={styles.container}>
					<div className={styles.actions}>
						{schedule && (
							<Typography variant="h2" gutterBottom>
								{schedule.summary}
							</Typography>
						)}
						<Typography variant="h3" gutterBottom>
							Check-in
						</Typography>
						<LinkButton
							variant="contained"
							color="primary"
							to={createActivityLocation}
							size="large"
							className={styles.cta}
							disabled={current.length > 0}
						>
							Start a new Check-in
						</LinkButton>
						<Typography variant="h4">
							Share this link to invite
						</Typography>
						<CopyText text={url} margin="dense" />
					</div>
					<div className={styles.activities}>
						<div>
							<Typography variant="h4" gutterBottom>
								Current Check-in
							</Typography>
							{current.length > 0 ? (
								<div className={styles.list}>
									{current?.map((checkin) => (
										<CheckinTile
											activity={checkin}
											key={checkin.activityId}
										/>
									))}
								</div>
							) : (
								<Typography variant="subtitle1">
									No current checkins, start one today!
								</Typography>
							)}
						</div>
						<div>
							<Typography variant="h4" gutterBottom>
								Check-in History
							</Typography>
							{previous.length > 0 ? (
								<div className={styles.list}>
									{previous?.map((checkin) => (
										<CheckinTile
											activity={checkin}
											key={checkin.activityId}
										/>
									))}
								</div>
							) : (
								<Typography variant="subtitle2">
									No previous check-ins
								</Typography>
							)}
						</div>
					</div>
				</div>
			</Container>
		</div>
	);
};
