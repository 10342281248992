import React from 'react';
import { Room } from '@contracts/room';
import type { RequestState } from '@common/store-tools';
import { useBackendFunction, useCurrentUser } from '@common/hooks';
import { useCurrentAccount } from '@common/contexts';
import { isError, isSuccess } from '@common/store-tools';
import { useParams } from 'react-router-dom';
import { useDocumentPath } from '../../store/helpers';
import { CheckinActivityListItem, CheckinUsersState } from '@contracts/checkin';
import { DateTime } from 'luxon';

type RoomActivities = {
	current: CheckinActivityListItem[];
	previous: CheckinActivityListItem[];
};

type ValidState = {
	url: string;
	room: Room;
	currentUserUid: string;
	activities: RoomActivities;
};

type State = RequestState<ValidState>;

type Params = {
	roomId: string;
};

const activitiesReducer =
	(from: DateTime = DateTime.now()) =>
	(
		acc: RoomActivities,
		activity: CheckinActivityListItem,
	): RoomActivities => {
		const createdAt = DateTime.fromISO(activity.createdAt);
		const diff = from.diff(createdAt, 'days').days;

		if (diff <= 1 && activity.activityStage !== 'summary') {
			acc.current.push(activity);
		} else {
			acc.previous.push(activity);
		}

		return acc;
	};

function uniqueHostAndParticipants(
	activity: CheckinActivityListItem,
): CheckinActivityListItem {
	return {
		...activity,
		usersState: Object.values(activity.usersState).reduce(
			(acc, userState) => {
				const uid: string = userState.uid;
				const currentUserState = activity.usersState[uid];
				if (
					currentUserState &&
					['host', 'participant'].includes(currentUserState.role)
				) {
					acc[uid] = currentUserState;
				}
				return acc;
			},
			{} as CheckinUsersState,
		),
	};
}

export const useState = (): State => {
	const { roomId } = useParams<Params>();
	const { uid: currentUserUid } = useCurrentUser();
	const { accounts, currentAccountId, setCurrentAccount } =
		useCurrentAccount();

	const [joinRequest] = useBackendFunction('room-join', {
		callOnChange: () => {
			return {
				roomId,
			};
		},
	});
	const joinSuccess = isSuccess(joinRequest);
	const joinError = isError(joinRequest) ? joinRequest.error : null;

	const getRequest = useDocumentPath(
		joinSuccess &&
			roomId && {
				path: ['rooms', roomId],
			},
	);

	const getError = isError(getRequest) ? getRequest.error : undefined;
	const getSuccess = isSuccess(getRequest);
	const room: Room | undefined = getRequest.data;
	const isMemberOfRoomAccount = Boolean(
		accounts?.find(
			(account) => room && account.accountId === room.accountId,
		),
	);

	const [listCheckinsRequest, listCheckins] = useBackendFunction(
		'checkin-list',
		{
			callOnChange: () => {
				return (
					joinSuccess &&
					getSuccess &&
					room &&
					isMemberOfRoomAccount && {
						roomId: room.roomId,
						accountId: room.accountId,
					}
				);
			},
			initialValue: [],
		},
	);
	const listCheckinsError = isError(listCheckinsRequest)
		? listCheckinsRequest.error
		: null;

	const currentUrl = window.location.href;

	const error = joinError || getError || listCheckinsError;

	const success = joinSuccess && getSuccess;

	React.useEffect(() => {
		if (room) {
			listCheckins();

			if (currentAccountId !== room.accountId) {
				setCurrentAccount(room.accountId);
			}
		}
	}, [currentAccountId, listCheckins, room, setCurrentAccount]);

	const activities = React.useMemo(
		() =>
			(listCheckinsRequest.data as CheckinActivityListItem[])
				.map(uniqueHostAndParticipants)
				.reduce(activitiesReducer(), {
					current: [],
					previous: [],
				} as RoomActivities),
		[listCheckinsRequest.data],
	);

	const result: State = React.useMemo<State>(() => {
		if (room && currentUserUid && success && !error) {
			return {
				status: 'success' as const,
				data: {
					room,
					currentUserUid,
					url: currentUrl,
					activities,
				},
			};
		}

		if (error) {
			return {
				status: 'error' as const,
				error,
			};
		}

		return { status: 'pending' as const };
	}, [room, currentUserUid, success, error, currentUrl, activities]);

	return result;
};
