import React from 'react';
import { CheckinActivityListItem } from '@contracts/checkin';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import { User } from './user';
import { routes } from '../routes';
import { LinkButton } from '@common';
import { useLocation } from 'react-router';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		activity: {
			display: 'flex',
			flexDirection: 'column',
			padding: theme.spacing(2),
			border: '2px solid #E0E0E0',
			borderRadius: '5px',
			textTransform: 'none',
		},
		currentActivity: {
			border: `2px solid ${theme.palette.primary.main}`,
		},
		heading: {
			display: 'flex',
			alignContent: 'center',
			justifyContent: 'space-between',
			width: '100%',
		},
		currentHeading: {
			color: theme.palette.primary.main,
		},
		users: {
			display: 'flex',
			flexWrap: 'wrap',
			gap: theme.spacing(2),
			margin: theme.spacing(2, 0, 0),
		},
	}),
);

type Props = {
	activity: CheckinActivityListItem;
};

function isCurrentActivity(activity: CheckinActivityListItem) {
	const from = DateTime.now();
	const createdAt = DateTime.fromISO(activity.createdAt);
	const diff = from.diff(createdAt, 'days').days;

	return diff <= 1 && activity.activityStage !== 'summary';
}

export const CheckinTile: React.ComponentType<Props> = ({ activity }) => {
	const styles = useStyles();
	const location = useLocation();
	const usersState = Object.values(activity.usersState);
	const isCurrent = isCurrentActivity(activity);

	const heading = isCurrent
		? `${DateTime.fromISO(activity.createdAt)
				.toFormat('h:mma')
				.toLocaleLowerCase()} Today`
		: DateTime.fromISO(activity.createdAt).toFormat('EEE dd MMMM yyyy');

	return (
		<div
			className={clsx(styles.activity, {
				[styles.currentActivity]: isCurrent,
			})}
		>
			<div
				className={clsx(styles.heading, {
					[styles.currentHeading]: isCurrent,
				})}
			>
				<Typography variant={'h4'}>{heading}</Typography>
				<LinkButton
					variant={isCurrent ? 'contained' : 'outlined'}
					color={isCurrent ? 'primary' : 'default'}
					to={routes.checkin({
						activityId: activity.activityId,
						location,
					})}
				>
					<span>{isCurrent ? 'Join' : 'View'}</span>
				</LinkButton>
			</div>
			<div>
				<Typography variant={'subtitle2'}>
					{usersState.length} participant
					{usersState.length > 1 ? 's' : ''}
				</Typography>
			</div>
			<div className={styles.users}>
				{usersState.map((userState) => {
					const uid = userState.uid;

					if (!uid) {
						return <></>;
					}
					const isHost = userState?.role === 'host';

					return (
						<User
							key={`user-${userState.uid}`}
							isHost={isHost}
							photoURL={userState.photoURL}
							imageSize={50}
							displayName={userState.displayName}
						/>
					);
				})}
			</div>
		</div>
	);
};
