import React from 'react';
import {
	isError,
	isSuccess,
	useBackendFunction,
	useCurrentAccount,
} from '@common';
import { routes } from '../../routes';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelectAccountState } from '@remote-social/common/src/routes/Auth/selectAccount';

type State = {
	status: 'pending' | 'error' | 'ok';
	error?: Error;
};

type LocationState = {
	roomId?: string;
	accountId?: string;
};

export const useState = (): State => {
	const location = useLocation<LocationState>();
	const roomId = location.state?.roomId;
	const accountId = location.state?.accountId;
	const { currentAccountId, accounts, loadingAccounts } = useCurrentAccount();
	const history = useHistory();
	const { accountWasSelected, setAccountWasSelected } =
		useSelectAccountState();

	const [createActivityRequest, createActivity] =
		useBackendFunction('checkin-create');

	const success = isSuccess(createActivityRequest);
	const error = isError(createActivityRequest)
		? createActivityRequest.error
		: undefined;

	const shouldSelectAccount =
		!accountId &&
		!accountWasSelected &&
		!loadingAccounts &&
		accounts &&
		accounts.length > 1 &&
		!error;

	React.useEffect(() => {
		if (shouldSelectAccount) {
			// TODO: Implement AccountSelectedRoute
			history.replace(
				routes.common.selectAccount({
					preserveContinuePath: true,
					location,
				}),
			);
		}
	}, [history, location, shouldSelectAccount]);

	React.useEffect(() => {
		if (!shouldSelectAccount && currentAccountId) {
			createActivity({
				accountId: accountId || currentAccountId,
				roomId,
			});
		}
	}, [
		accountId,
		currentAccountId,
		createActivity,
		roomId,
		shouldSelectAccount,
	]);

	React.useEffect(() => {
		if (isSuccess(createActivityRequest)) {
			history.replace(
				routes.lobby({
					activityId: createActivityRequest.data.activityId,
					location,
				}),
			);
			setAccountWasSelected(false);
		}
	}, [createActivityRequest, setAccountWasSelected, history, location]);

	React.useEffect(() => {
		if (isError(createActivityRequest)) {
			setAccountWasSelected(false);
		}
	}, [createActivityRequest, setAccountWasSelected, history]);

	const result = React.useMemo(() => {
		if (success) {
			return {
				status: 'ok' as const,
			};
		} else if (error) {
			return {
				status: 'error' as const,
				error,
			};
		} else {
			return { status: 'pending' as const };
		}
	}, [error, success]);

	return result;
};
