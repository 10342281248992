import type { FlatSchema } from '@contracts/shared';
import {
	combineReducers,
	makeFirebaseReducer,
	makeFirestoreReducer,
} from '@common/store-tools';
import type { CheckinCollections } from '@contracts/checkin';

export const reducer = combineReducers({
	firebase: makeFirebaseReducer(),
	firestore: makeFirestoreReducer<FlatSchema<CheckinCollections>>(),
});
