import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { DocumentHead, Loading, PageError } from '@common/components';
import { useCheckin } from './state';
import { Users } from './users/users';
import { Deck } from './deck/deck';
import { CardSelect } from './card-select/cardSelect';
import { Presenting } from './presenting/presenting';
import { Summary } from './summary/summary';
import { Banner } from './banner/banner';
import clsx from 'clsx';
import { ClaimHost } from '../../components/claimHost';

const useStyles = makeStyles((theme) => {
	return createStyles({
		checkin: {
			position: 'relative',
			display: 'grid',
			gridTemplateAreas: `
				'banner'
				'users'
				'main'
			`,
			gridTemplateColumns: 'auto',
			gridTemplateRows:
				'minmax(0, max-content) minmax(0, max-content) minmax(0, max-content)',
			width: '100%',
			height: '100%',
			gap: theme.spacing(0),

			[theme.breakpoints.up('md')]: {
				gridTemplateAreas: `
					'users banner'
					'users main'
				`,
				gridTemplateColumns: 'minmax(0, max-content) 1fr',
				gridTemplateRows: 'minmax(0, min-content) max-content',
			},
		},
		presenting: {
			[theme.breakpoints.down('sm')]: {
				gridTemplateAreas: `
				'banner'
				'main'
				'users'
			`,
				gridTemplateRows:
					'minmax(0, max-content) auto minmax(0, min-content)',
			},
		},
		banner: {
			gridArea: 'banner',
		},
		users: {
			gridArea: 'users',
		},
		main: {
			gridArea: 'main',
		},
		deck: {
			position: 'sticky',
			bottom: 0,
			left: 0,
		},
	});
});

export const Checkin: React.ComponentType = () => {
	const state = useCheckin();
	const styles = useStyles();

	if (state.status === 'error' && state.error) {
		return <PageError error={state.error} />;
	}

	if (state.status === 'loading' || state.status !== 'ok') {
		return <Loading showRandomLabels />;
	}

	const {
		activity,
		usersPresence,
		currentUserUid,
		usersStatus,
		imageCards,
		wordCards,
		selectedImageCard,
		selectedWordCard,
		currentPresenter,
		nextPresenter,
		participantsState,
		currentUserState,
	} = state;

	const activityStage = activity.activityStage;
	const currentUserStatus = usersStatus[currentUserUid];

	const showBanner =
		currentUserStatus && activity.activityStage !== 'summary';

	const showUsers = [
		'image-card-select',
		'word-card-select',
		'presenting',
	].includes(activityStage);

	const showDeck =
		currentUserStatus &&
		currentUserStatus !== 'spectating' &&
		['image-card-select', 'word-card-select'].includes(activityStage);

	return (
		<div
			className={clsx(styles.checkin, {
				[styles.presenting]: activityStage === 'presenting',
			})}
		>
			<DocumentHead title="Check-in" />
			{showBanner && (
				<Banner
					userStatus={currentUserStatus}
					className={styles.banner}
				/>
			)}

			{showUsers && (
				<Users
					activity={activity}
					currentUserState={currentUserState}
					participantsState={participantsState}
					usersPresence={usersPresence}
					usersStatus={usersStatus}
					className={styles.users}
				/>
			)}

			{activityStage === 'image-card-select' && (
				<CardSelect
					activityId={activity.activityId}
					participantsState={participantsState}
					userStatus={currentUserStatus}
					cards={imageCards}
					className={styles.main}
				/>
			)}

			{activityStage === 'word-card-select' && (
				<CardSelect
					activityId={activity.activityId}
					participantsState={participantsState}
					userStatus={currentUserStatus}
					cards={wordCards}
					className={styles.main}
				/>
			)}

			{activityStage === 'presenting' && currentPresenter && (
				<Presenting
					activity={activity}
					usersStatus={usersStatus}
					participantsState={participantsState}
					currentUserUid={currentUserUid}
					currentPresenter={currentPresenter}
					nextPresenter={nextPresenter}
					className={styles.main}
				/>
			)}

			{activityStage === 'summary' && (
				<Summary
					activity={activity}
					participantsState={participantsState}
					usersPresence={usersPresence}
					usersStatus={usersStatus}
					currentUserUid={currentUserUid}
					className={styles.main}
				/>
			)}

			{showDeck && (
				<Deck
					userStatus={currentUserStatus}
					selectedImageCard={selectedImageCard}
					selectedWordCard={selectedWordCard}
					className={styles.deck}
				/>
			)}

			<ClaimHost
				activityId={activity.activityId}
				activityStage={activity.activityStage}
				participantsState={participantsState}
				usersPresence={usersPresence}
			/>
		</div>
	);
};
