import { buildRoute, createRoutes, routes as common } from '@common/routes';

type CreateActivityParams = { accountId?: string; roomId?: string };

export const routes = createRoutes({
	home: common.home,

	createActivity: buildRoute(
		'/create-activity',
		(opts: CreateActivityParams) => ({
			state: {
				accountId: opts?.accountId,
				roomId: opts?.roomId,
			},
		}),
	),

	createScheduledRoom: buildRoute('/create-scheduled-room'),

	room: buildRoute('/room/:roomId', (opts: { roomId: string }) => ({
		pathname: `/room/${opts.roomId}`,
	})),

	lobby: buildRoute('/lobby/:activityId', (opts: { activityId: string }) => ({
		pathname: `/lobby/${opts.activityId}`,
	})),

	checkin: buildRoute(
		'/checkin/:activityId',
		(opts: { activityId: string }) => ({
			pathname: `/checkin/${opts.activityId}`,
		}),
	),

	common,
});
