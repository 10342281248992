import { CheckinActivityListItem } from '@contracts/checkin';
import { useBackendFunction } from '@common/hooks';
import React from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { isError, isSuccess, RequestState } from '@common/store-tools';
import { CheckinTile } from '../../components/checkinTile';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		recentActivities: {
			flex: 1,
		},
		list: {
			display: 'grid',
			gap: theme.spacing(2, 0),
		},
	}),
);

type ValidState = {
	recentActivities: CheckinActivityListItem[];
};

type State = RequestState<ValidState>;

type Props = { currentAccountId: string };

const useState = ({ currentAccountId }: Props): State => {
	const [listActivities] = useBackendFunction('checkin-list', {
		callOnChange: () => {
			return { accountId: currentAccountId, limit: 3 };
		},
		initialValue: [] as CheckinActivityListItem[],
	});

	const success = isSuccess(listActivities);
	const error = isError(listActivities) ? listActivities.error : undefined;
	const stageOrder = [
		'presenting',
		'word-card-select',
		'image-card-select',
		'lobby',
		'summary',
	];

	const recentActivities = (listActivities.data as CheckinActivityListItem[])
		?.sort(
			(a, b) =>
				stageOrder.indexOf(a.activityStage) -
				stageOrder.indexOf(b.activityStage),
		)
		?.sort((a, b) => Number(a.createdAt) - Number(b.createdAt));

	const result = React.useMemo(() => {
		if (success) {
			return {
				status: 'success' as const,
				data: {
					recentActivities,
				},
			};
		}

		if (error) {
			return {
				status: 'error' as const,
				error,
			};
		}

		return {
			status: 'pending' as const,
		};
	}, [error, recentActivities, success]);

	return result;
};

export const RecentActivities = ({ currentAccountId }: Props): JSX.Element => {
	const styles = useStyles();
	const state = useState({ currentAccountId });

	if (state.status === 'error' && state.error) {
		return <></>;
	}

	if (state.status === 'pending' || state.status !== 'success') {
		return <></>;
	}

	const {
		data: { recentActivities },
	} = state;

	return (
		<div className={styles.recentActivities}>
			<Typography variant="h2" align="center" gutterBottom>
				Recent Check-ins
			</Typography>
			{!recentActivities.length ? (
				<Typography variant="body1" align="center">
					No recent Check-ins, start one today!
				</Typography>
			) : (
				<div className={styles.list}>
					{recentActivities?.map((activity) => (
						<CheckinTile
							activity={activity}
							key={activity.activityId}
						/>
					))}
				</div>
			)}
		</div>
	);
};
