import React from 'react';
import type { UsersStatus, UserStatus } from '../../../store/getUsersStatus';
import type { UserPresence } from '@contracts/activity-presence';
import { CheckinUserState } from '@contracts/checkin';
import { ErrorType, isError, isPending, useBackendFunction } from '@common';

type State = {
	showPresenterActions: boolean;
	showHostActions: boolean;
	userStatus?: UserStatus;
	onFinishTurn: () => void;
	isFinishTurnPending: boolean;
	error?: ErrorType;
};

type Props = {
	activityId: string;
	participantsState: CheckinUserState[];
	usersStatus: UsersStatus;
	currentPresenter?: UserPresence;
	currentUserUid: string;
};

export const usePresenting = ({
	activityId,
	participantsState,
	usersStatus,
	currentPresenter,
	currentUserUid,
}: Props): State => {
	const [finishPresentingTurnRequest, initiateFinishPresentingTurnRequest] =
		useBackendFunction('checkin-finishPresentingTurn');

	const error = isError(finishPresentingTurnRequest)
		? finishPresentingTurnRequest.error
		: undefined;
	const pending = isPending(finishPresentingTurnRequest);

	const onFinishTurn = React.useCallback(() => {
		initiateFinishPresentingTurnRequest({
			activityId,
		});
	}, [activityId, initiateFinishPresentingTurnRequest]);

	const result = React.useMemo(() => {
		const userStatus = usersStatus[currentUserUid];
		const participantState = participantsState.find(
			(participantState) => participantState.uid === currentUserUid,
		);
		const showPresenterActions = currentUserUid === currentPresenter?.uid;
		const showHostActions = participantState?.role === 'host' || false;

		return {
			userStatus,
			showPresenterActions,
			showHostActions,
			onFinishTurn,
			isFinishTurnPending: pending,
			error,
		};
	}, [
		usersStatus,
		currentUserUid,
		participantsState,
		currentPresenter?.uid,
		onFinishTurn,
		pending,
		error,
	]);

	return result;
};
