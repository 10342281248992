import type { UserStatus } from '../../../store/getUsersStatus';
import { UnreachableError } from '@common';

type State = {
	statusText: string;
};

type Props = {
	userStatus: UserStatus;
};

export const useDeck = ({ userStatus }: Props): State => {
	let statusText;
	switch (userStatus) {
		case 'selecting-image-card':
			statusText = 'Pick a card';
			break;
		case 'selecting-word-card':
			statusText = 'Pick another card';
			break;
		case 'selected-image-card':
		case 'selected-word-card':
			statusText = 'All done! Waiting for others';
			break;
		case 'pending':
		case 'spectating':
		case 'presenting-presenter':
		case 'presenting-listener':
			statusText = '';
			break;
		default:
			throw new UnreachableError(userStatus);
	}

	return {
		statusText,
	};
};
