import React from 'react';
import {
	ErrorType,
	isError,
	isPending,
	isSuccess,
	useBackendFunction,
	useCurrentAccount,
} from '@common';
import { routes } from '../../routes';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelectAccountState } from '@common/routes/Auth/selectAccount';
import { CreateRoomScheduleParams } from '@contracts/room';

type State =
	| {
			status: 'ok';
			onConfirmCallback: (schedule: CreateRoomScheduleParams) => void;
	  }
	| { status: 'pending' }
	| {
			status: 'error';
			error: ErrorType;
	  };

export const useCreateRoom = (): State => {
	const location = useLocation();
	const { currentAccountId, accounts, loadingAccounts } = useCurrentAccount();
	const history = useHistory();
	const { accountWasSelected, setAccountWasSelected } =
		useSelectAccountState();

	const [createRoomRequest, createRoom] = useBackendFunction('room-create');

	const pending = isPending(createRoomRequest);
	const success = isSuccess(createRoomRequest);
	const error = isError(createRoomRequest)
		? createRoomRequest.error
		: undefined;

	const shouldSelectAccount =
		!accountWasSelected &&
		!loadingAccounts &&
		accounts &&
		accounts.length > 1 &&
		!error;

	React.useEffect(() => {
		if (shouldSelectAccount) {
			// TODO: Implement AccountSelectedRoute
			history.replace(
				routes.common.selectAccount({
					preserveContinuePath: true,
					location,
				}),
			);
		}
	}, [history, location, shouldSelectAccount]);

	React.useEffect(() => {
		if (isSuccess(createRoomRequest)) {
			history.replace(
				routes.room({
					roomId: createRoomRequest.data.roomId,
					location,
				}),
			);
			setAccountWasSelected(false);
		}
	}, [createRoomRequest, setAccountWasSelected, history, location]);

	React.useEffect(() => {
		if (isError(createRoomRequest)) {
			setAccountWasSelected(false);
		}
	}, [createRoomRequest, setAccountWasSelected, history]);

	const onConfirmCallback = React.useCallback(
		(schedule: CreateRoomScheduleParams) => {
			if (currentAccountId) {
				createRoom({
					accountId: currentAccountId,
					activityTypes: schedule ? [] : ['checkin'],
					schedule,
				});
			}
		},
		[createRoom, currentAccountId],
	);

	const result = React.useMemo(() => {
		if (!pending || success) {
			return {
				status: 'ok' as const,
				onConfirmCallback,
			};
		} else if (error) {
			return {
				status: 'error' as const,
				error,
			};
		} else {
			return { status: 'pending' as const };
		}
	}, [error, onConfirmCallback, pending, success]);

	return result;
};
