import { createStyles, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import HowDoesItWork1 from '../../assets/how-does-it-work-1.png';
import HowDoesItWork2 from '../../assets/how-does-it-work-2.png';
import HowDoesItWork3 from '../../assets/how-does-it-work-3.png';
import { Actions } from './actions';

const useStyles = makeStyles((theme) =>
	createStyles({
		howDoesItWork: {
			display: 'grid',
		},
		title: {
			padding: theme.spacing(2, 0),
			textAlign: 'center',

			[theme.breakpoints.up('md')]: {
				padding: theme.spacing(4, 0, 8),
			},
		},
		points: {
			display: 'grid',
			gap: theme.spacing(6),

			[theme.breakpoints.up('md')]: {
				gridTemplateColumns: 'repeat(3, 1fr)',
			},
		},
		point: {
			padding: theme.spacing(4),

			[theme.breakpoints.up('md')]: {
				padding: theme.spacing(4),
			},
		},
		pointImgContainer: { position: 'relative' },
		pointImgBadge: {
			position: 'absolute',
			top: '-30px',
			left: '-30px',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			width: '60px',
			height: '60px',
			fontSize: '36px',
			lineHeight: '47px',
			fontWeight: 900,
			backgroundColor: theme.palette.primary.main,
			color: theme.palette.common.white,
			borderRadius: '50%',
		},
		pointImg: {
			width: '100%',
			height: 'auto',
			objectFit: 'contain',
		},
		cta: {
			padding: theme.spacing(5, 0),

			[theme.breakpoints.up('md')]: {
				alignSelf: 'center',
				justifySelf: 'center',
			},
		},
	}),
);

export const HowDoesItWork: React.ComponentType = () => {
	const styles = useStyles();

	return (
		<div className={styles.howDoesItWork}>
			<Typography variant="h2" className={styles.title}>
				How does it work?
			</Typography>
			<div className={styles.points}>
				<div className={styles.point}>
					<div className={styles.pointImgContainer}>
						<div className={styles.pointImgBadge}>1</div>
						<img
							src={HowDoesItWork1}
							alt="Remote Social Check-in How-to"
							className={styles.pointImg}
						/>
					</div>
					<Typography variant="subtitle1">
						Start by picking an image card that best supports how
						you are feeling today
					</Typography>
				</div>
				<div className={styles.point}>
					<div className={styles.pointImgContainer}>
						<div className={styles.pointImgBadge}>2</div>
						<img
							src={HowDoesItWork2}
							alt="Remote Social Check-in How-to"
							className={styles.pointImg}
						/>
					</div>
					<Typography variant="subtitle1">
						Then pick a word card that also helps support how you
						are feeling today
					</Typography>
				</div>
				<div className={styles.point}>
					<div className={styles.pointImgContainer}>
						<div className={styles.pointImgBadge}>3</div>
						<img
							src={HowDoesItWork3}
							alt="Remote Social Check-in How-to"
							className={styles.pointImg}
						/>
					</div>
					<Typography variant="subtitle1">
						Tell a story about how you are feeling using the cards
						as a framing device
					</Typography>
				</div>
			</div>
			<div className={styles.cta}>
				<Actions />
			</div>
		</div>
	);
};
