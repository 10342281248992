import {
	CheckinActivity,
	CheckinUserState,
	isParticipant,
} from '@contracts/checkin';
import { ensureExists } from '@common';

type Props = {
	activity: CheckinActivity;
};

export const getParticipantUsersState = ({
	activity,
}: Props): CheckinUserState[] => {
	const uids = activity.uids;
	const presenters = activity.presenters;

	return activity.activityStage === 'presenting'
		? presenters
				.filter((uid) => {
					const user = activity.usersState?.[uid];
					return user && isParticipant(user);
				})
				.map((uid) => ensureExists(activity.usersState[uid]))
		: uids
				.filter((uid) => {
					const user = activity.usersState?.[uid];
					return user && isParticipant(user);
				})
				.map((uid) => ensureExists(activity.usersState[uid]));
};
