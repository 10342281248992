import React from 'react';
import {
	CopyText,
	DocumentHead,
	Loading,
	LoadingButton,
	PageError,
} from '@remote-social/common/src';
import {
	Container,
	createStyles,
	makeStyles,
	Typography,
} from '@material-ui/core';
import { useLobby } from './state';
import { User } from '../../components';
import Introduction from '../../assets/introduction.mp4';
import IntroductionPoster from '../../assets/introduction-poster.png';
import { ClaimHost } from '../../components/claimHost';

const useStyles = makeStyles((theme) =>
	createStyles({
		lobby: {
			backgroundColor: theme.palette.background.default,
			padding: theme.spacing(2, 0),

			[theme.breakpoints.up('sm')]: {
				padding: theme.spacing(4, 0),
			},
		},
		container: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(4),

			[theme.breakpoints.up('md')]: {
				flexDirection: 'row-reverse',
				justifyContent: 'space-between',
			},
		},
		state: {
			display: 'flex',
			flexDirection: 'column',
			flexGrow: 1,
			flexBasis: 0,
			justifyContent: 'space-between',
			minWidth: 0,
		},
		users: {
			display: 'flex',
			flexWrap: 'wrap',
			gap: theme.spacing(2),
			margin: theme.spacing(2, 0),
		},
		actions: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(2),
			marginBottom: theme.spacing(0.7),
		},
		information: {
			flexGrow: 1,
			flexBasis: 0,
			minWidth: 0,
		},
		video: {
			width: '100%',
			borderRadius: '1.5rem',
		},
	}),
);

export const Lobby: React.ComponentType = () => {
	const state = useLobby();
	const styles = useStyles();

	if (state.status === 'error' && state.error) {
		return <PageError error={state.error} />;
	}

	if (state.status === 'loading' || state.status !== 'ok') {
		return <Loading showRandomLabels />;
	}

	const {
		activity,
		url,
		usersPresence,
		participantsState,
		onStartActivity,
		currentUserState,
		usersStatus,
		startPending,
	} = state;

	const activityStage = activity.activityStage;
	const isCurrentUserHost = currentUserState?.role === 'host' || false;

	return (
		<div className={styles.lobby}>
			<DocumentHead title="Lobby" />
			<Container fixed maxWidth="md">
				<div className={styles.container}>
					<div className={styles.state}>
						<div>
							<Typography variant="h1" gutterBottom>
								Check-in
							</Typography>
							<Typography variant="h4">
								Your team ({participantsState.length})
							</Typography>

							<div className={styles.users}>
								{participantsState.map((participantState) => {
									const userPresence = usersPresence.find(
										(userPresence) =>
											userPresence.uid ===
											participantState.uid,
									);
									const userStatus =
										usersStatus[participantState.uid];
									const isHost =
										participantState.role === 'host';

									return (
										<User
											key={`user-${participantState.uid}`}
											activityStage={activityStage}
											isHost={isHost}
											userStatus={userStatus}
											displayName={
												userPresence?.displayName
											}
											photoURL={userPresence?.photoURL}
											connected={userPresence?.connected}
											imageSize={50}
										/>
									);
								})}
							</div>
						</div>

						<div className={styles.actions}>
							<div>
								<Typography variant="h4">
									Share this link to invite
								</Typography>
								<CopyText text={url} margin="dense" />
							</div>

							{isCurrentUserHost ? (
								<LoadingButton
									variant="contained"
									color="primary"
									onClick={onStartActivity}
									loading={startPending}
									disabled={startPending}
								>
									Start Check-in, everyone's here
								</LoadingButton>
							) : (
								<Typography variant="subtitle1">
									Waiting for the host to start the
									check-in...
								</Typography>
							)}
						</div>
					</div>

					<div className={styles.information}>
						<video
							className={styles.video}
							playsInline={true}
							loop={true}
							controls={false}
							autoPlay={true}
							poster={IntroductionPoster}
							muted
						>
							<source src={Introduction} type="video/mp4" />
						</video>
					</div>

					<ClaimHost
						activityId={activity.activityId}
						activityStage={activity.activityStage}
						participantsState={participantsState}
						usersPresence={usersPresence}
					/>
				</div>
			</Container>
		</div>
	);
};
