import React from 'react';
import { RoomListItem } from '@contracts/room';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { DateTime } from 'luxon';
import { routes } from '../routes';
import { LinkButton } from '@common';
import { useLocation } from 'react-router';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		room: {
			display: 'flex',
			flexDirection: 'column',
			padding: theme.spacing(2),
			border: '2px solid #E0E0E0',
			borderRadius: '5px',
			textTransform: 'none',
		},
		currentActivity: {
			border: `2px solid ${theme.palette.primary.main}`,
		},
		heading: {
			display: 'flex',
			alignContent: 'center',
			justifyContent: 'space-between',
			width: '100%',
		},
		currentHeading: {
			color: theme.palette.primary.main,
		},
		users: {
			display: 'flex',
			flexWrap: 'wrap',
			gap: theme.spacing(2),
			margin: theme.spacing(2, 0, 0),
		},
	}),
);

type Props = {
	room: RoomListItem;
};

function isCurrentRoom(room: RoomListItem) {
	const from = DateTime.now();
	const createdAt = DateTime.fromISO(room.createdAt);
	const diff = from.diff(createdAt, 'days').days;

	return diff <= 1;
}

export const RoomTile: React.ComponentType<Props> = ({ room }) => {
	const styles = useStyles();
	const location = useLocation();
	const isCurrent = isCurrentRoom(room);

	const heading = room?.schedule?.summary || 'Room';

	return (
		<div
			className={clsx(styles.room, {
				[styles.currentActivity]: isCurrent,
			})}
		>
			<div
				className={clsx(styles.heading, {
					[styles.currentHeading]: isCurrent,
				})}
			>
				<div>
					<Typography variant={'h4'}>{heading}</Typography>
				</div>
				<LinkButton
					variant={isCurrent ? 'contained' : 'outlined'}
					color={isCurrent ? 'primary' : 'default'}
					to={routes.room({
						roomId: room.roomId,
						location,
					})}
				>
					<span>{isCurrent ? 'Join' : 'View'}</span>
				</LinkButton>
			</div>
		</div>
	);
};
