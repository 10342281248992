import { RoomListItem } from '@contracts/room';
import { useBackendFunction } from '@common/hooks';
import React from 'react';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { CheckinActivity } from '@contracts/checkin';
import { isError, isSuccess, RequestState } from '@common/store-tools';
import { RoomTile } from '../../components/roomTile';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		recentRooms: {
			flex: 1,
		},
		list: {
			display: 'grid',
			gap: theme.spacing(2, 0),
		},
	}),
);

type ValidState = {
	recentRooms: RoomListItem[];
};

type State = RequestState<ValidState>;

type Props = { currentAccountId: string };

const useState = ({ currentAccountId }: Props): State => {
	const [listRooms] = useBackendFunction('room-list', {
		callOnChange: () => {
			return { accountId: currentAccountId };
		},
		initialValue: [] as CheckinActivity[],
	});

	const success = isSuccess(listRooms);
	const error = isError(listRooms) ? listRooms.error : undefined;

	const recentRooms = (listRooms.data as RoomListItem[]).sort(
		(a, b) => Number(a.updatedAt) - Number(b.updatedAt),
	);

	const result = React.useMemo(() => {
		if (success) {
			return {
				status: 'success' as const,
				data: {
					recentRooms,
				},
			};
		}

		if (error) {
			return {
				status: 'error' as const,
				error,
			};
		}

		return {
			status: 'pending' as const,
		};
	}, [error, recentRooms, success]);

	return result;
};

export const RecentRooms = ({ currentAccountId }: Props): JSX.Element => {
	const styles = useStyles();
	const state = useState({
		currentAccountId,
	});

	if (state.status === 'error' && state.error) {
		return <></>;
	}

	if (state.status === 'pending' || state.status !== 'success') {
		return <></>;
	}

	const {
		data: { recentRooms },
	} = state;

	return (
		<div className={styles.recentRooms}>
			<Typography variant="h2" align="center" gutterBottom>
				Recent Rooms
			</Typography>

			{!recentRooms.length ? (
				<Typography variant="body1" align="center">
					No recent Rooms
				</Typography>
			) : (
				<div className={styles.list}>
					{recentRooms.map((room) => (
						<RoomTile room={room} key={room.roomId} />
					))}
				</div>
			)}
		</div>
	);
};
