import React from 'react';
import type { CheckinUserState } from '@contracts/checkin';
import type { ErrorType } from '@common';
import { isError, isPending, isSuccess, useBackendFunction } from '@common';

type State = {
	onCardSelect: (cardId: string) => void;
	checkCardSelected: (cardId: string) => boolean;
	error?: ErrorType;
	success?: boolean;
	cardSelectPending?: boolean;
};

type Props = {
	activityId: string;
	participantsState: CheckinUserState[];
};

export const useCardSelect = (props: Props): State => {
	const { activityId, participantsState } = props;

	const [selectCardRequest, initSelectCardRequest] =
		useBackendFunction('checkin-selectCard');

	const success = isSuccess(selectCardRequest);
	const error = isError(selectCardRequest)
		? selectCardRequest.error
		: undefined;
	const pending = isPending(selectCardRequest);

	const currentlySelectCardIds: string[] = participantsState.reduce<string[]>(
		(arr, val) => {
			if (val.imageCardId) {
				arr.push(val.imageCardId);
			}
			if (val.wordCardId) {
				arr.push(val.wordCardId);
			}

			return arr;
		},
		[],
	);

	const checkCardSelected = React.useCallback(
		(cardId: string): boolean => {
			return !!currentlySelectCardIds.find(
				(selectedCardId) => selectedCardId === cardId,
			);
		},
		[currentlySelectCardIds],
	);

	const onCardSelect = React.useCallback(
		(cardId: string) => {
			initSelectCardRequest({
				cardId,
				activityId,
			});
		},
		[activityId, initSelectCardRequest],
	);

	const result: State = React.useMemo(() => {
		return {
			success,
			error,
			cardSelectPending: pending,
			onCardSelect,
			checkCardSelected,
		};
	}, [success, error, pending, onCardSelect, checkCardSelected]);

	return result;
};
