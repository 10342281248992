import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import clsx from 'clsx';
import type { CheckinImageCard, CheckinWordCard } from '@contracts/checkin';
import { isImageCardType, isWordCardType } from '@contracts/checkin';

const useStyles = makeStyles((theme) => ({
	nobleCard: {
		display: 'flex',
		width: '100%',
		height: 'auto',
		aspectRatio: '8 / 5',
		boxShadow:
			'0 0 2px rgb(0 0 0 / 25%), 0px 0px 8px rgb(0 0 0 / 18%), 0px 8px 10px -6px rgb(1 50 120 / 30%)',
		borderRadius: '12px',
		border: '2px solid #fff',
		overflow: 'hidden',
		...theme.palette.cards['gray'],
	},
	yellow: {
		...theme.palette.cards['yellow'],
	},
	green: {
		...theme.palette.cards['green'],
	},
	orange: {
		...theme.palette.cards['orange'],
	},
	blue: {
		...theme.palette.cards['blue'],
	},
	magenta: {
		...theme.palette.cards['magenta'],
	},
	purple: {
		...theme.palette.cards['purple'],
	},
	cardTextCentered: {
		alignItems: 'center',
		justifyContent: 'center',
	},
	cardTextRightBottom: {
		alignItems: 'flex-end',
		justifyContent: 'flex-end',
	},
	image: {
		width: '100%',
		height: 'auto',
		objectFit: 'cover',
	},
	text: {
		margin: theme.spacing(1),
		textTransform: 'uppercase',
	},
	textSm: {
		fontSize: '12px',

		[theme.breakpoints.up('md')]: {
			fontSize: '18px',
		},
	},
	textLg: {
		fontSize: '14px',

		[theme.breakpoints.up('sm')]: {
			fontSize: '22px',
		},

		[theme.breakpoints.up('md')]: {
			fontSize: '32px',
		},
	},
}));

type Props = {
	card: CheckinWordCard | CheckinImageCard;
	textAlignment?: 'center' | 'right-bottom';
	variant?: 'lg' | 'sm';
	className?: string;
};

export const NobleCard: React.ComponentType<Props> = ({
	card,
	className,
	textAlignment = 'center',
	variant = 'lg',
}) => {
	const isImageCard = isImageCardType(card);
	const isWordCard = isWordCardType(card);

	const styles = useStyles();
	const category = isWordCard ? card.category : undefined;

	return (
		<span
			className={clsx(
				styles.nobleCard,
				{
					[styles.cardTextCentered]: textAlignment === 'center',
					[styles.cardTextRightBottom]:
						textAlignment === 'right-bottom',
					[styles.yellow]: category === 'dynamic-learning-system',
					[styles.green]: category === 'systems-integration',
					[styles.orange]: category === 'systems-discipline-late',
					[styles.blue]: category === 'systems-discipline-early',
					[styles.magenta]: category === 'transactions-management',
					[styles.purple]: category === 'muddling-through',
				},
				className,
			)}
		>
			{isImageCard && (
				<img className={styles.image} alt="Noble" src={card.imgSrc} />
			)}
			{isWordCard && (
				<Typography
					className={clsx(styles.text, {
						[styles.textSm]: variant === 'sm',
						[styles.textLg]: variant === 'lg',
					})}
					variant={'h5'}
				>
					{card.text}
				</Typography>
			)}
		</span>
	);
};
