import React from 'react';
import { ActivityLayout } from './activityLayout';
import { LandingLayout } from './landingLayout';

type Props = {
	variant?: 'landing' | 'activity';
};

export const Layout: React.ComponentType<Props> = ({
	variant = 'activity',
	children,
}) => {
	switch (variant) {
		case 'landing': {
			return <LandingLayout>{children}</LandingLayout>;
		}
		case 'activity': {
			return <ActivityLayout>{children}</ActivityLayout>;
		}
	}
};
