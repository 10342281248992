import React from 'react';
import LogoImg from '../assets/toolkit-logo-x2.png';
import {
	createStyles,
	makeStyles,
	Theme,
	useMediaQuery,
} from '@material-ui/core';

const useStyles = makeStyles(() =>
	createStyles({
		logoImg: {
			width: 'auto',
			height: '100%',
			objectFit: 'contain',
		},
	}),
);

const intrinsicSize = {
	width: 710,
	height: 208,
};

const aspectRatio = intrinsicSize.width / intrinsicSize.height;

type Props = {
	variant?: 'lg' | 'sm';
};

export const CheckInLogo: React.ComponentType<Props> = ({ variant = 'lg' }) => {
	const styles = useStyles();

	const isDesktop = useMediaQuery<Theme>((theme) =>
		theme.breakpoints.up('sm'),
	);
	const height = isDesktop
		? (intrinsicSize.height * 0.5) / (variant === 'lg' ? 1 : 2)
		: (intrinsicSize.height * 0.375) / (variant === 'lg' ? 1 : 2);

	return (
		<a href="/" style={{ height, width: aspectRatio * height }}>
			<img
				src={LogoImg}
				alt="Remote Social Check In"
				className={styles.logoImg}
			/>
		</a>
	);
};
