import { CheckInLogo } from '../../../components';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { useHeader } from './state';

const useStyles = makeStyles((theme) => {
	return createStyles({
		header: {
			position: 'relative',
			display: 'grid',
			width: '100%',
			padding: theme.spacing(2, 0, 1),

			[theme.breakpoints.up('md')]: {
				padding: theme.spacing(4, 0),
			},
		},
		logo: { display: 'grid', margin: '0 auto' },
		title: {
			marginTop: theme.spacing(1),

			[theme.breakpoints.up('md')]: {
				marginTop: theme.spacing(2),
			},
		},
	});
});

type Props = {
	className?: string;
};

export const Header: React.ComponentType<Props> = ({ className }) => {
	const { titleVariant } = useHeader();
	const styles = useStyles();

	return (
		<div className={clsx(styles.header, className)}>
			<div className={styles.logo}>
				<CheckInLogo variant="sm" />
			</div>
			<Typography
				className={styles.title}
				align="center"
				variant={titleVariant}
			>
				Check-in
			</Typography>
		</div>
	);
};
