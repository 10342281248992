import React from 'react';
import ChoosingFirstCard from '../assets/choosing-first-card.svg';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import ChosenFirstCard from '../assets/chosen-first-card.svg';
import ChoosingSecondCard from '../assets/choosing-second-card.svg';
import ChosenSecondCard from '../assets/chosen-second-card.svg';
import type { UserStatus } from '../store/getUsersStatus';
import { UnreachableError } from '@common';

const useStyles = makeStyles(() =>
	createStyles({
		success: {
			color: '#4CAF50',
		},
		pending: { color: 'rgba(0, 0, 0, 0.54)' },
	}),
);

type Props = {
	userStatus: UserStatus;
};

export const UserStatusParts: React.ComponentType<Props> = ({ userStatus }) => {
	const styles = useStyles();

	switch (userStatus) {
		case 'selecting-image-card':
			return (
				<>
					<img alt="Choosing card" src={ChoosingFirstCard} />
					<Typography
						variant={'subtitle2'}
						className={styles.pending}
					>
						Choosing Card
					</Typography>
				</>
			);

		case 'selected-image-card':
			return (
				<>
					<img alt="Chosen card" src={ChosenFirstCard} />
					<Typography
						variant={'subtitle2'}
						className={styles.success}
					>
						Card Chosen
					</Typography>
				</>
			);
		case 'selecting-word-card':
			return (
				<>
					<img alt="Choosing card" src={ChoosingSecondCard} />
					<Typography
						variant={'subtitle2'}
						className={styles.pending}
					>
						Choosing Card
					</Typography>
				</>
			);
		case 'selected-word-card':
			return (
				<>
					<img alt="Chosen card" src={ChosenSecondCard} />
					<Typography
						variant={'subtitle2'}
						className={styles.success}
					>
						Card Chosen
					</Typography>
				</>
			);
		case 'pending':
		case 'spectating':
		case 'presenting-presenter':
		case 'presenting-listener':
			return null;
		default:
			throw new UnreachableError(userStatus);
	}
};
