import React from 'react';
import { Container, createStyles, makeStyles } from '@material-ui/core';
import { DocumentHead } from '@common/components';
import ToolkitLandingHeroBg from '../../assets/toolkit-landing-hero-bg.svg';
import { Hero } from './hero';
import { HowDoesItWork } from './how-does-it-work';
import { MadeEasy } from './made-easy';
import { RecentRooms } from './recentRooms';
import { RecentActivities } from './recentActivities';
import { useCurrentAccount } from '@common/contexts';
import { useCurrentUser } from '@common/hooks';

const useStyles = makeStyles((theme) =>
	createStyles({
		home: {
			background: `white url(${ToolkitLandingHeroBg}) right top no-repeat`,
		},
		container: {
			display: 'grid',
			placeItems: 'center',
			gridTemplateRows: 'auto',
			gap: theme.spacing(2),
		},
		recent: {
			display: 'flex',
			flexDirection: 'column',
			gap: theme.spacing(6),
			width: '100%',

			[theme.breakpoints.up('md')]: {
				flexDirection: 'row',
				justifyContent: 'center',
				gap: theme.spacing(12),
			},

			[theme.breakpoints.up('lg')]: {
				gap: theme.spacing(16),
			},
		},
	}),
);

export const Home: React.ComponentType = () => {
	const { uid } = useCurrentUser();
	const { currentAccountId } = useCurrentAccount();
	const styles = useStyles();

	return (
		<div className={styles.home}>
			<DocumentHead title="Welcome" />
			<Container fixed maxWidth="lg" className={styles.container}>
				<Hero />
				{uid && currentAccountId && (
					<div className={styles.recent}>
						<RecentRooms currentAccountId={currentAccountId} />
						<RecentActivities currentAccountId={currentAccountId} />
					</div>
				)}
				<MadeEasy />
				<HowDoesItWork />
			</Container>
		</div>
	);
};
