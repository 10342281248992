import { isDevBuild } from '@remote-social/common/src/environment';
import { createStore } from '@remote-social/common/src/store-tools';
import { reducer } from './plumbing';

export const store = createStore(reducer);

export type CheckInReduxState = ReturnType<typeof store['getState']>;

if (isDevBuild() && module.hot) {
	module.hot.accept('./plumbing', () => {
		const { reducer } = require('./plumbing');
		store.replaceReducer(reducer);
	});
}
