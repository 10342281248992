import type { CheckinCardType } from './checkinActivity';

export const isImageCardType = <T extends { cardType: CheckinCardType }>(
	card: T,
): card is Extract<T, { cardType: 'image' }> => {
	return card.cardType === 'image';
};

export const isWordCardType = <T extends { cardType: CheckinCardType }>(
	card: T,
): card is Extract<T, { cardType: 'word' }> => {
	return card.cardType === 'word';
};
