import { Theme, useMediaQuery } from '@material-ui/core';

type State = {
	titleVariant: 'xs' | 'lg';
	avatarVariant: 'xs' | 'lg';
};

export const useSummary = (): State => {
	const isXs = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));
	const titleVariant = isXs ? 'xs' : 'lg';
	const avatarVariant = isXs ? 'xs' : 'lg';

	return {
		titleVariant,
		avatarVariant,
	};
};
