import {
	Button,
	createStyles,
	Hidden,
	makeStyles,
	Menu,
	MenuItem,
} from '@material-ui/core';
import React from 'react';
import { routes } from '../../../routes';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useHistory, useLocation } from 'react-router-dom';
import { CheckinUserState } from '@contracts/checkin';

const useStyles = makeStyles((theme) => {
	return createStyles({
		actions: {
			display: 'flex',
			gap: theme.spacing(2),
		},
	});
});

type State = {
	onActionMenuClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
	onActionMenuClose: () => void;
	anchorEl: Element | null;
	actionMenuOpen: boolean;
	onStartNewCheckin: () => void;
	onGoToRoom: () => void;
	isCurrentUserHost: boolean;
};

const useState = ({
	participantsState,
	currentUserUid,
	accountId,
	roomId,
}: Props): State => {
	const history = useHistory();
	const location = useLocation();

	const [anchorEl, setAnchorEl] = React.useState(null);
	const actionMenuOpen = Boolean(anchorEl);

	const onActionMenuClick = React.useCallback((event) => {
		setAnchorEl(event.currentTarget);
	}, []);

	const onActionMenuClose = React.useCallback(() => {
		setAnchorEl(null);
	}, []);

	const onStartNewCheckin = React.useCallback(() => {
		history.push(routes.createActivity({ accountId, roomId, location }));
	}, [accountId, history, location, roomId]);

	const onGoToRoom = React.useCallback(() => {
		if (roomId) {
			history.push(
				routes.room({
					roomId,
					location,
				}),
			);
		}
	}, [history, location, roomId]);

	const currentUserState = participantsState.find(
		(participantState) => participantState.uid === currentUserUid,
	);
	const isCurrentUserHost = currentUserState?.role === 'host' || false;
	return {
		onActionMenuClick,
		onActionMenuClose,
		anchorEl,
		actionMenuOpen,
		onStartNewCheckin,
		onGoToRoom,
		isCurrentUserHost,
	};
};

type Props = {
	participantsState: CheckinUserState[];
	currentUserUid: string;
	accountId: string;
	roomId?: string;
};

export const Actions: React.ComponentType<Props> = ({
	participantsState,
	currentUserUid,
	accountId,
	roomId,
}) => {
	const styles = useStyles();
	const {
		onActionMenuClick,
		onActionMenuClose,
		anchorEl,
		actionMenuOpen,
		onStartNewCheckin,
		onGoToRoom,
		isCurrentUserHost,
	} = useState({ participantsState, currentUserUid, accountId, roomId });

	const showActions = isCurrentUserHost || roomId !== undefined;

	return (
		<div className={styles.actions}>
			{showActions && (
				<>
					<Hidden smDown>
						{isCurrentUserHost && (
							<Button
								variant="contained"
								color="primary"
								onClick={onStartNewCheckin}
							>
								Start New Check-in
							</Button>
						)}
						{roomId && (
							<Button
								variant="outlined"
								color="primary"
								onClick={onGoToRoom}
							>
								Back to room
							</Button>
						)}
					</Hidden>
					<Hidden mdUp>
						<Button
							type="button"
							id="action-button"
							aria-controls="action-menu"
							aria-haspopup="true"
							aria-expanded={actionMenuOpen ? 'true' : undefined}
							onClick={onActionMenuClick}
						>
							<MoreVertIcon />
						</Button>
						<Menu
							id="action-menu"
							anchorEl={anchorEl}
							open={actionMenuOpen}
							onClose={onActionMenuClose}
							transformOrigin={{
								horizontal: 'right',
								vertical: 'top',
							}}
							anchorOrigin={{
								horizontal: 'right',
								vertical: 'bottom',
							}}
							MenuListProps={{
								'aria-labelledby': 'action-button',
							}}
						>
							{isCurrentUserHost && (
								<MenuItem onClick={onStartNewCheckin}>
									Start New Check-in
								</MenuItem>
							)}
							{roomId && (
								<MenuItem onClick={onGoToRoom}>
									Back to room
								</MenuItem>
							)}
						</Menu>
					</Hidden>
				</>
			)}
		</div>
	);
};
