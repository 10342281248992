import { DocumentHead, Loading, PageError } from '@common/components';
import { useState } from './state';
import React from 'react';

export const CreateActivity: React.ComponentType = () => {
	const { status, error } = useState();

	if (error) {
		return <PageError error={error} />;
	}
	if (status === 'pending') {
		return <Loading showRandomLabels />;
	}

	return (
		<div>
			<DocumentHead title="Creating activity" />
		</div>
	);
};
