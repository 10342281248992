import type { ErrorType } from '@common';
import { useCurrentUser } from '@common/hooks';
import { useCurrentAccount } from '@common/contexts';
import React from 'react';
import type { UserPresence } from '@contracts/activity-presence';
import { useActivityPresence } from '../../store';
import type {
	CheckinActivity,
	CheckinImageCard,
	CheckinUserState,
	CheckinWordCard,
} from '@contracts/checkin';
import { isImageCardType, isWordCardType } from '@contracts/checkin';
import type { UsersStatus } from '../../store/getUsersStatus';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { routes } from '../../routes';
import { useJoinAndGetActivity } from '../../store/useJoinAndGetActivity';
import { getParticipantUsersState } from '../../store/getParticipantUsersState';
import { getUsersStatus } from '../../store/getUsersStatus';

type State =
	| {
			status: 'ok';
			activity: CheckinActivity;
			usersPresence: UserPresence[];
			imageCards: CheckinImageCard[];
			wordCards: CheckinWordCard[];
			currentUserUid: string;
			currentUserState?: CheckinUserState;
			usersStatus: UsersStatus;
			participantsState: CheckinUserState[];
			selectedWordCard?: CheckinWordCard;
			selectedImageCard?: CheckinImageCard;
			currentPresenter?: UserPresence;
			nextPresenter?: UserPresence;
	  }
	| {
			status: 'loading';
	  }
	| {
			status: 'error';
			error: ErrorType;
	  };

type Params = {
	activityId: string;
};

export const useCheckin = (): State => {
	const { activityId } = useParams<Params>();
	const history = useHistory();
	const location = useLocation();
	const { uid: currentUserUid } = useCurrentUser();
	const { currentAccountId, setCurrentAccount } = useCurrentAccount();

	const joinAndGetActivity = useJoinAndGetActivity({ activityId });
	const error = joinAndGetActivity.error;
	const activity = joinAndGetActivity.data?.activity;

	const { usersPresence } = useActivityPresence();
	const userPresence = usersPresence.find(
		(userPresence) => userPresence.uid === currentUserUid,
	);

	const activityStage = activity?.activityStage;

	React.useEffect(() => {
		window.scroll(0, 0);
	}, [activityStage]);

	React.useEffect(() => {
		if (activity?.activityId && activityStage === 'lobby') {
			history.push(
				routes.lobby({
					activityId: activity.activityId,
					location,
				}),
			);
		}
	}, [activity?.activityId, activityStage, history, location]);

	React.useEffect(() => {
		if (activity?.accountId && currentAccountId !== activity.accountId) {
			setCurrentAccount(activity.accountId);
		}
	}, [activity, currentAccountId, setCurrentAccount]);

	const result: State = React.useMemo(() => {
		if (
			currentUserUid &&
			activity &&
			usersPresence &&
			userPresence &&
			!error
		) {
			const imageCards = activity.cards.filter(isImageCardType);
			const wordCards = activity.cards.filter(isWordCardType);

			const participantsState = getParticipantUsersState({
				activity,
			});

			const usersNotPresented = participantsState.filter(
				(participantState) => !participantState.presented,
			);

			const currentPresenterUid = usersNotPresented[0]?.uid;
			const currentPresenter = currentPresenterUid
				? usersPresence.find(
						(userPresence) =>
							userPresence.uid === currentPresenterUid,
				  )
				: undefined;

			const nextPresenterUid = usersNotPresented[1]?.uid;
			const nextPresenter = nextPresenterUid
				? usersPresence.find(
						(userPresence) => userPresence.uid === nextPresenterUid,
				  )
				: undefined;

			const currentUserState = participantsState.find(
				(participantState) => participantState.uid === currentUserUid,
			);
			const selectedImageCard = imageCards.find(
				(card) => card.checkinCardId === currentUserState?.imageCardId,
			);
			const selectedWordCard = wordCards.find(
				(card) => card.checkinCardId === currentUserState?.wordCardId,
			);
			const usersStatus = getUsersStatus({
				activity,
				participantsState,
				currentPresenterUid,
			});

			return {
				status: 'ok',
				activity,
				usersPresence,
				imageCards,
				wordCards,
				selectedImageCard,
				selectedWordCard,
				currentUserUid,
				currentUserState,
				usersStatus,
				currentPresenter,
				nextPresenter,
				participantsState,
			};
		} else if (error) {
			return {
				status: 'error',
				error,
			};
		} else {
			return { status: 'loading' };
		}
	}, [currentUserUid, activity, usersPresence, userPresence, error]);

	return result;
};
