import { User, UserCardStatus } from '../../../components';
import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { deckHeightLg } from '../deck/deck';
import clsx from 'clsx';
import type { UserPresence } from '@contracts/activity-presence';
import type { UsersStatus } from '../../../store/getUsersStatus';
import type { CheckinActivity, CheckinUserState } from '@contracts/checkin';
import { HostUserMenu } from '../../../components/hostUserMenu';

export const usersWidthLg = '270px';

const useStyles = makeStyles((theme) => {
	return createStyles({
		users: {
			width: '100%',

			[theme.breakpoints.up('md')]: {
				flexDirection: 'column',
				flexWrap: 'nowrap',
				placeContent: 'start',
				width: `${usersWidthLg}`,
				backgroundColor: 'rgba(189, 189, 189, 0.2)',
				borderRight: '1px solid rgba(158, 158, 158, 1)',
			},
		},
		sticky: {
			[theme.breakpoints.up('md')]: {
				position: 'sticky',
				top: 0,
				left: 0,
			},
		},
		scroll: {
			display: 'flex',
			justifySelf: 'start',
			placeContent: 'start',
			flexWrap: 'nowrap',
			gap: theme.spacing(2),
			width: '100vw',
			padding: theme.spacing(2),
			overflow: 'auto',
			overflowY: 'hidden',

			[theme.breakpoints.up('sm')]: {
				placeContent: 'center',
			},

			[theme.breakpoints.up('md')]: {
				flexDirection: 'column',
				flexWrap: 'nowrap',
				placeContent: 'start',
				width: 'auto',
				height: `calc(100vh - ${deckHeightLg})`,
				overflow: 'auto',
				overflowX: 'hidden',
			},
		},
		scrollPresenting: {
			[theme.breakpoints.up('md')]: {
				height: '100vh',
			},
		},
		userOuter: {
			display: 'flex',
			flexDirection: 'row',
		},
		userWrapper: {
			border: '4px solid transparent',
			borderRadius: theme.spacing(1),

			[theme.breakpoints.up('md')]: {
				display: 'flex',
				justifyContent: 'space-around',
				alignItems: 'flex-end',
				width: '100%',
				padding: theme.spacing(2, 1),
				borderRadius: theme.spacing(2),
				boxShadow:
					'0 0 2px rgb(0 0 0 / 25%), 0px 0px 8px rgb(0 0 0 / 18%), 0px 8px 10px -6px rgb(1 50 120 / 30%)',
				background: theme.palette.background.default,
			},
		},

		// user is 'presenting' cards
		presentingUsers: {
			position: 'sticky',
			bottom: 0,
			left: 0,
			backgroundColor: '#E0E0E0',
			zIndex: 1,

			[theme.breakpoints.up('md')]: {
				position: 'relative',
				backgroundColor: '#E0E0E0',
			},
		},
		presentingUserWrapper: {
			padding: theme.spacing(1),

			[theme.breakpoints.up('md')]: {
				padding: theme.spacing(2, 1),
			},
		},
		currentlyPresenting: {
			background: 'rgba(199, 255, 190, 0.3)',
			border: '4px solid #27AE60',
			borderRadius: theme.spacing(1),

			[theme.breakpoints.up('md')]: {
				borderRadius: theme.spacing(2),
			},
		},
		presentingIndex: {
			padding: theme.spacing(0, 1),
		},
		hasPresented: {
			opacity: 0.6,
		},
	});
});

type UsersProps = {
	activity: CheckinActivity;
	usersPresence: UserPresence[];
	usersStatus: UsersStatus;
	currentUserState?: CheckinUserState;
	participantsState: CheckinUserState[];
	className?: string;
};

export const Users: React.ComponentType<UsersProps> = ({
	activity,
	usersPresence,
	usersStatus,
	currentUserState,
	participantsState,
	className,
}) => {
	const styles = useStyles();

	const activityId = activity.activityId;
	const activityStage = activity.activityStage;
	const isPresentingStage = activityStage === 'presenting';
	const shouldShowHostMenu =
		currentUserState?.role === 'host' &&
		['image-card-select', 'word-card-select', 'presenting'].includes(
			activityStage,
		);

	return (
		<div
			className={clsx(
				styles.users,
				{
					[styles.presentingUsers]: isPresentingStage,
				},
				className,
			)}
		>
			<div className={styles.sticky}>
				<div
					className={clsx(styles.scroll, {
						[styles.scrollPresenting]: isPresentingStage,
					})}
				>
					{participantsState.map((participantState) => {
						const uid = participantState.uid;
						const userStatus = usersStatus[uid];
						const userPresence = usersPresence.find(
							(participantState) => participantState.uid === uid,
						);
						const userIsPresenting =
							userStatus === 'presenting-presenter';
						const userHasPresented =
							isPresentingStage && participantState.presented;
						const isHost = participantState.role === 'host';
						const isCurrentUser =
							participantState.uid === currentUserState?.uid;

						return (
							<div
								key={`user-${uid}`}
								className={styles.userOuter}
							>
								<div
									className={clsx(styles.userWrapper, {
										[styles.presentingUserWrapper]:
											isPresentingStage,
										[styles.currentlyPresenting]:
											userIsPresenting,
									})}
								>
									<User
										className={clsx({
											[styles.hasPresented]:
												userHasPresented,
										})}
										activityStage={activityStage}
										isHost={isHost}
										userStatus={userStatus}
										displayName={userPresence?.displayName}
										photoURL={userPresence?.photoURL}
										connected={userPresence?.connected}
									>
										{shouldShowHostMenu &&
											!isCurrentUser && (
												<HostUserMenu
													activityId={activityId}
													uid={uid}
													displayName={
														userPresence?.displayName
													}
													role={participantState.role}
												/>
											)}
									</User>
									{userStatus && (
										<UserCardStatus
											userStatus={userStatus}
										/>
									)}
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};
