import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	Menu,
	MenuItem,
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React from 'react';
import { RoleType } from '@contracts/checkin';
import { isPending, isSuccess, useBackendFunction } from '@common';

type Props = {
	activityId: string;
	uid: string;
	displayName?: string;
	role?: RoleType;
};

export const HostUserMenu = ({ activityId, uid, displayName, role }: Props) => {
	const [deactivateUserRequest, deactivateUser] = useBackendFunction(
		'checkin-deactivateUser',
	);
	const pending = isPending(deactivateUserRequest);

	const isDeactivated = role === 'deactivated';

	const [dialogOpen, setDialogOpen] = React.useState(false);
	const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);

	const onMenuClick = React.useCallback((event) => {
		setMenuAnchorEl(event.currentTarget);
	}, []);

	const onMenuClose = React.useCallback(() => {
		setMenuAnchorEl(null);
	}, []);

	const onDialogOpen = React.useCallback(() => {
		setDialogOpen(true);
		onMenuClose();
	}, [onMenuClose]);

	const onDialogClose = React.useCallback(() => {
		setDialogOpen(false);
	}, []);

	const onDeactivateUser = React.useCallback(() => {
		deactivateUser({
			uid,
			activityId,
		});
		onDialogClose();
	}, [activityId, deactivateUser, onDialogClose, uid]);

	React.useEffect(() => {
		if (isSuccess(deactivateUserRequest)) {
			onMenuClose();
		}
	}, [deactivateUserRequest, onMenuClose]);

	return (
		<Box display="flex" justifyContent="center" alignItems="center">
			<IconButton
				aria-controls="simple-menu"
				aria-haspopup="true"
				size="small"
				onClick={onMenuClick}
			>
				<MoreHorizIcon />
			</IconButton>
			<Menu
				id="simple-menu"
				anchorEl={menuAnchorEl}
				keepMounted
				open={Boolean(menuAnchorEl)}
				onClose={onMenuClose}
			>
				<MenuItem
					onClick={onDialogOpen}
					disabled={pending || isDeactivated}
				>
					{pending ? 'Removing...' : 'Remove'}
				</MenuItem>
			</Menu>
			<Dialog
				open={dialogOpen}
				onClose={onDialogClose}
				aria-labelledby="confirm-remove-user"
				aria-describedby="confirm-remove-user"
			>
				<DialogTitle>Remove user</DialogTitle>
				<DialogContent>
					Are you sure you want to remove{' '}
					<strong>{displayName || 'this user'}</strong>?
				</DialogContent>
				<DialogActions>
					<Button
						variant="text"
						color="default"
						onClick={onDialogClose}
						size="small"
					>
						Cancel
					</Button>
					<Button
						variant="outlined"
						color="secondary"
						onClick={onDeactivateUser}
						size="small"
					>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</Box>
	);
};
