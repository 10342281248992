import React from 'react';
import {
	createStyles,
	Hidden,
	makeStyles,
	Typography,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import {
	ErrorSnackbar,
	ErrorType,
	isError,
	isPending,
	LoadingButton,
	useBackendFunction,
} from '@common';
import type { CheckinUserState } from '@contracts/checkin';
import type { UserPresence } from '@contracts/activity-presence';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => {
	return createStyles({
		hostActions: {
			justifySelf: 'center',
		},
		hostPreviousBtn: {
			marginRight: theme.spacing(1),
		},
	});
});

type State = {
	onHostNextPresenter: () => void;
	onHostPreviousPresenter: () => void;
	hostNextPresenterRequestPending: boolean;
	hostPreviousPresenterRequestPending: boolean;
	hostControlsPending: boolean;
	hasPreviousPresenter: boolean;
	error?: ErrorType;
};

type Props = {
	currentUserUid: string;
	activityId: string;
	participantsState: CheckinUserState[];
	nextPresenter?: UserPresence;
	className?: string;
};

const useHostActions = ({
	currentUserUid,
	activityId,
	participantsState,
}: Props): State => {
	const [hostNextPresenterRequest, initiateHostNextPresenterRequest] =
		useBackendFunction('checkin-hostNextPresenter');
	const hostNextPresenterRequestPending = isPending(hostNextPresenterRequest);

	const [hostPreviousPresenterRequest, initiateHostPreviousPresenterRequest] =
		useBackendFunction('checkin-hostPreviousPresenter');
	const hostPreviousPresenterRequestPending = isPending(
		hostPreviousPresenterRequest,
	);
	const hostNextPresenterError = isError(hostNextPresenterRequest)
		? hostNextPresenterRequest.error
		: undefined;
	const hostPreviousPresenterError = isError(hostPreviousPresenterRequest)
		? hostPreviousPresenterRequest.error
		: undefined;

	const hostControlsPending =
		hostNextPresenterRequestPending || hostPreviousPresenterRequestPending;

	const finishedPresenters = participantsState.filter(
		(participantState) => participantState.presented,
	);
	const hasPreviousPresenter = finishedPresenters.length > 0;

	const currentUserState = participantsState.find(
		(participantState) => participantState.uid === currentUserUid,
	);

	const isCurrentUserHost = currentUserState?.role === 'host' || false;

	const onHostNextPresenter = React.useCallback(() => {
		if (isCurrentUserHost) {
			initiateHostNextPresenterRequest({ activityId });
		}
	}, [activityId, initiateHostNextPresenterRequest, isCurrentUserHost]);

	const onHostPreviousPresenter = React.useCallback(() => {
		if (isCurrentUserHost) {
			initiateHostPreviousPresenterRequest({ activityId });
		}
	}, [activityId, initiateHostPreviousPresenterRequest, isCurrentUserHost]);

	const result = React.useMemo(
		() => ({
			onHostNextPresenter,
			hostNextPresenterRequestPending,
			hostPreviousPresenterRequestPending,
			hostControlsPending,
			onHostPreviousPresenter,
			hasPreviousPresenter,
			error: hostNextPresenterError || hostPreviousPresenterError,
		}),
		[
			hasPreviousPresenter,
			hostControlsPending,
			hostNextPresenterError,
			hostNextPresenterRequestPending,
			hostPreviousPresenterError,
			hostPreviousPresenterRequestPending,
			onHostNextPresenter,
			onHostPreviousPresenter,
		],
	);

	return result;
};

export const HostActions: React.ComponentType<Props> = ({
	currentUserUid,
	activityId,
	nextPresenter,
	participantsState,
	className,
}) => {
	const {
		onHostNextPresenter,
		hostNextPresenterRequestPending,
		hostPreviousPresenterRequestPending,
		hostControlsPending,
		onHostPreviousPresenter,
		hasPreviousPresenter,
		error,
	} = useHostActions({
		currentUserUid,
		activityId,
		nextPresenter,
		participantsState,
	});
	const styles = useStyles();

	return (
		<div className={clsx(styles.hostActions, className)}>
			{error && (
				<ErrorSnackbar
					error="Sorry! We could not move the presenter, please try again."
					extraErrorInfo={error?.message}
				/>
			)}
			<Typography variant="subtitle2">Host controls</Typography>
			<LoadingButton
				variant="outlined"
				color="primary"
				onClick={onHostPreviousPresenter}
				loading={hostPreviousPresenterRequestPending}
				disabled={!hasPreviousPresenter || hostControlsPending}
				className={styles.hostPreviousBtn}
			>
				<ArrowBackIcon />
				<Hidden smDown>Back a turn</Hidden>
			</LoadingButton>
			<LoadingButton
				variant="outlined"
				color="primary"
				onClick={onHostNextPresenter}
				loading={hostNextPresenterRequestPending}
				disabled={!nextPresenter || hostControlsPending}
			>
				{' '}
				<Hidden smDown>Next turn</Hidden>
				<ArrowForwardIcon />
			</LoadingButton>
		</div>
	);
};
