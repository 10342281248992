import { Button, Container, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { NobleCard } from '../../../components';
import type { CheckinImageCard, CheckinWordCard } from '@contracts/checkin';
import { CheckinUserState } from '@contracts/checkin';
import clsx from 'clsx';
import { useCardSelect } from './state';
import type { UserStatus } from '../../../store/getUsersStatus';
import { ErrorSnackbar } from '@remote-social/common/src';

const useStyles = makeStyles((theme) => {
	return createStyles({
		cardSelect: {
			display: 'grid',
			gridTemplateColumns: 'repeat(2, 1fr)',
			justifySelf: 'center',
			placeContent: 'center',
			width: '100%',
			padding: theme.spacing(0, 0, 3),
			gap: theme.spacing(3),

			[theme.breakpoints.up('sm')]: {
				padding: theme.spacing(1, 1, 4),
				gap: theme.spacing(4),
			},

			[theme.breakpoints.up('md')]: {
				gridTemplateColumns: 'repeat(2, 1fr)',
				padding: theme.spacing(5, 2),
				gap: theme.spacing(5),
			},

			[theme.breakpoints.up('lg')]: {
				gridTemplateColumns: 'repeat(3, 1fr)',
			},
		},
		cardButton: {
			position: 'relative',
			padding: 0,
			borderRadius: '12px',
			transition: 'opacity 0.2s',

			'&:disabled': {
				opacity: '0.4',
			},
		},
	});
});

type Props = {
	activityId: string;
	participantsState: CheckinUserState[];
	cards: (CheckinImageCard | CheckinWordCard)[];
	userStatus?: UserStatus;
	className?: string;
};

export const CardSelect: React.ComponentType<Props> = ({
	activityId,
	participantsState,
	userStatus,
	cards,
	className,
}) => {
	const { cardSelectPending, checkCardSelected, onCardSelect, error } =
		useCardSelect({ activityId, participantsState });
	const styles = useStyles();

	return (
		<Container fixed maxWidth="lg">
			{error && (
				<ErrorSnackbar
					error="Sorry! The card was not selected, please try again."
					extraErrorInfo={error?.message}
				/>
			)}
			<div className={clsx(styles.cardSelect, className)}>
				{userStatus &&
					cards.map((card) => {
						const selected = checkCardSelected(card.checkinCardId);
						const disabled =
							userStatus === 'spectating' ||
							cardSelectPending ||
							selected;

						return (
							<Button
								type="button"
								key={card.checkinCardId}
								className={styles.cardButton}
								disabled={disabled}
								onClick={() => onCardSelect(card.checkinCardId)}
							>
								<NobleCard card={card} />
							</Button>
						);
					})}
			</div>
		</Container>
	);
};
