import {
	createStyles,
	Hidden,
	makeStyles,
	Theme,
	Typography,
	useMediaQuery,
} from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';
import { useBanner } from './state';
import { UserStatus } from '../../../store/getUsersStatus';

const bannerImgWidthXs = 70;
const bannerImgWidthLg = 100;

const useStyles = makeStyles((theme) => {
	return createStyles({
		banner: {
			display: 'flex',
			justifyContent: 'center',
			padding: theme.spacing(2),
			background:
				'linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #4CAF50;',
		},
		bannerImg: {
			height: '70px',
			marginLeft: `-${bannerImgWidthXs}px`,

			[theme.breakpoints.up('lg')]: {
				height: '90px',
				marginLeft: `-${bannerImgWidthLg}px`,
			},
		},
		bannerText: {
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
			textAlign: 'center',
			maxWidth: '32ch',

			[theme.breakpoints.up('sm')]: {
				padding: theme.spacing(1),
				maxWidth: '40ch',
			},

			[theme.breakpoints.up('md')]: {
				padding: theme.spacing(2),
			},
		},
		bannerTitle: {
			margin: '0 auto',
		},
		bannerSubtitle: {
			margin: '0 auto',
		},
	});
});

type Props = {
	userStatus: UserStatus;
	className?: string;
};

export const Banner: React.ComponentType<Props> = ({
	userStatus,
	className,
}) => {
	const isLg = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'));
	const { hidden, imgSrc, title, body } = useBanner({
		userStatus,
	});
	const styles = useStyles();

	if (hidden) {
		return <></>;
	}

	return (
		<div className={clsx(styles.banner, className)}>
			<Hidden smDown>
				<img
					className={styles.bannerImg}
					src={imgSrc}
					alt="Banner explainer"
					width={isLg ? bannerImgWidthLg : bannerImgWidthXs}
				/>
			</Hidden>
			<div className={styles.bannerText}>
				<Typography className={styles.bannerTitle} variant="h4">
					{title}
				</Typography>
				<Typography className={styles.bannerSubtitle} variant="body2">
					{body}
				</Typography>
			</div>
		</div>
	);
};
