import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { AppLayout } from '@common/components';

const useStyles = makeStyles(() =>
	createStyles({
		appLayout: {
			overflow: 'unset',
		},
		mainLayout: {
			padding: '0',
			background: `linear-gradient(to bottom, #F8F8F8, #F8F8F8 5%, white 95%);`,
			width: '100%',
			height: '100%',
		},
	}),
);

export const ActivityLayout: React.ComponentType = ({ children }) => {
	const styles = useStyles();
	return (
		<AppLayout
			className={styles.appLayout}
			mainContentClassName={styles.mainLayout}
		>
			{children}
		</AppLayout>
	);
};
