import React from 'react';
import type { UserPresence } from '@contracts/activity-presence';
import type { CheckinActivity, CheckinUserState } from '@contracts/checkin';
import { isImageCardType } from '@contracts/checkin';
import { ErrorType } from '@common/errors';
import {
	useBackendFunction,
	useCurrentUser,
	usePrefetchImages,
} from '@common/hooks';
import { useCurrentAccount } from '@common/contexts';
import { isError, isPending } from '@common/store-tools';
import { useActivityPresence } from '../../store';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { routes } from '../../routes';
import type { UsersStatus } from '../../store/getUsersStatus';
import { getUsersStatus } from '../../store/getUsersStatus';
import { getParticipantUsersState } from '../../store/getParticipantUsersState';
import { Theme, useMediaQuery } from '@material-ui/core';
import { useJoinAndGetActivity } from '../../store/useJoinAndGetActivity';

type State =
	| {
			status: 'ok';
			url: string;
			activity: CheckinActivity;
			currentUserUid: string;
			currentUserState?: CheckinUserState;
			usersPresence: UserPresence[];
			onStartActivity: () => void;
			usersStatus: UsersStatus;
			participantsState: CheckinUserState[];
			startPending: boolean;
	  }
	| { status: 'loading' }
	| { status: 'error'; error: ErrorType };

type Params = {
	activityId: string;
};

export const useLobby = (): State => {
	const { activityId } = useParams<Params>();
	const history = useHistory();
	const location = useLocation();
	const isLg = useMediaQuery<Theme>((theme) => theme.breakpoints.up('lg'));
	const { uid: currentUserUid } = useCurrentUser();
	const { currentAccountId, setCurrentAccount } = useCurrentAccount();

	const joinAndGetActivity = useJoinAndGetActivity({ activityId });
	const joinAndGetActivityError = joinAndGetActivity.error;
	const activity = joinAndGetActivity.data?.activity;

	const { usersPresence } = useActivityPresence();
	const userPresence = usersPresence.find(
		(userPresence) => userPresence.uid === currentUserUid,
	);
	const currentUrl = window.location.href;

	const [startActivity, startActivityInitiateRequest] =
		useBackendFunction('checkin-start');

	const startPending = isPending(startActivity) || false;
	const startError = isError(startActivity) ? startActivity.error : undefined;

	const error = joinAndGetActivityError || startError;

	const onStartActivity = React.useCallback(() => {
		if (activity?.activityId) {
			startActivityInitiateRequest({
				activityId: activity.activityId,
			});
		}
	}, [activity?.activityId, startActivityInitiateRequest]);

	const activityStage = activity?.activityStage;

	React.useEffect(() => {
		if (
			activity?.activityId &&
			activityStage &&
			activityStage !== 'lobby'
		) {
			history.push(
				routes.checkin({
					activityId: activity.activityId,
					location,
				}),
			);
		}
	}, [activity?.activityId, history, activityStage, location]);

	React.useEffect(() => {
		if (activity?.accountId && currentAccountId !== activity.accountId) {
			setCurrentAccount(activity.accountId);
		}
	}, [activity, currentAccountId, setCurrentAccount]);

	const imgSrcs = activity?.cards
		.filter(isImageCardType)
		.map((card) => card.imgSrc)
		.slice(0, isLg ? 15 : 8);

	usePrefetchImages({
		once: true,
		images: imgSrcs || [],
	});

	const result: State = React.useMemo<State>(() => {
		if (activity && currentUserUid && userPresence && !error) {
			const participantsState = getParticipantUsersState({
				activity,
			});
			const usersStatus = getUsersStatus({
				activity,
				participantsState,
			});
			const currentUserState = participantsState.find(
				(participantState) => participantState.uid === currentUserUid,
			);

			return {
				status: 'ok' as const,
				activity,
				currentUserUid,
				currentUserState,
				url: currentUrl,
				usersPresence,
				onStartActivity,
				participantsState,
				usersStatus,
				startPending,
			};
		} else if (error) {
			return {
				status: 'error' as const,
				error,
			};
		} else {
			return { status: 'loading' as const };
		}
	}, [
		activity,
		currentUserUid,
		userPresence,
		error,
		currentUrl,
		usersPresence,
		onStartActivity,
		startPending,
	]);

	return result;
};
