import React from 'react';
import {
	Avatar,
	createStyles,
	makeStyles,
	Theme,
	Typography,
	useMediaQuery,
} from '@material-ui/core';
import Check from '../assets/check.svg';
import clsx from 'clsx';
import type { UserStatus } from '../store/getUsersStatus';
import type { CheckinActivityStage } from '@contracts/checkin';

const imageSizeXs = '2.5rem';
const imageSizeLg = '3.75rem';

const useStyles = makeStyles((theme) =>
	createStyles({
		user: {
			position: 'relative',
			width: imageSizeXs,

			[theme.breakpoints.up('md')]: {
				width: imageSizeLg,
			},
		},
		avatarWrapper: {
			position: 'relative',
		},
		avatar: {
			border: '1px solid #E0E0E0',
		},
		avatarHostUser: {
			borderColor: '#FFD700',
			boxShadow: '0 0 0 1px #FFD700',
		},
		badge: {
			position: 'absolute',
			top: '-6px',
			left: '50%',
			transform: 'translate(-50%, 0)',
			height: '12px',
			padding: '0 6px',
			borderRadius: '6px',
			background: 'inherit',
			borderColor: 'inherit',
			fontWeight: 500,
			fontStyle: 'normal',
			fontSize: '10px',
			lineHeight: '12px',
		},
		hostBadge: {
			background: '#FFD700',
		},
		ready: {
			position: 'absolute',
			bottom: '-6px',
			right: '-6px',
			width: '16px',
			height: '16px',
			background: '#fff',
			borderRadius: '50%',
			overflow: 'hidden',
			zIndex: 1,
		},
		disconnected: {
			opacity: 0.4,
		},
		displayName: {
			marginTop: theme.spacing(0.5),
			whiteSpace: 'nowrap',
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			textAlign: 'center',
		},
		status: {
			[theme.breakpoints.up('md')]: {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				alignSelf: 'flex-end',
			},
		},
	}),
);

type Props = {
	isHost: boolean;
	displayName?: string;
	photoURL?: string;
	connected?: boolean;
	userStatus?: UserStatus;
	activityStage?: CheckinActivityStage;
	shouldShowRole?: boolean;
	imageSize?: number;
	className?: string;
};

export const User: React.ComponentType<Props> = ({
	isHost,
	displayName,
	photoURL,
	connected,
	userStatus,
	activityStage,
	shouldShowRole = true,
	imageSize,
	className,
	children,
}) => {
	const isXs = useMediaQuery<Theme>((theme: Theme) =>
		theme.breakpoints.down('xs'),
	);
	const styles = useStyles();

	const isReady =
		(activityStage === 'image-card-select' &&
			userStatus === 'selected-image-card') ||
		(activityStage === 'word-card-select' &&
			userStatus === 'selected-word-card');

	const fixedImageSize = imageSize
		? imageSize
		: isXs
		? imageSizeXs
		: imageSizeLg;

	return (
		<div
			className={clsx(
				styles.user,
				{ [styles.disconnected]: connected === false },
				className,
			)}
			style={{
				width: fixedImageSize,
			}}
		>
			<div className={styles.avatarWrapper}>
				<Avatar
					alt={displayName}
					variant="rounded"
					src={photoURL}
					title={displayName}
					className={clsx(styles.avatar, {
						[styles.avatarHostUser]: shouldShowRole && isHost,
					})}
					style={{ width: fixedImageSize, height: fixedImageSize }}
				/>
				{shouldShowRole && isHost && (
					<span className={clsx(styles.badge, styles.hostBadge)}>
						Host
					</span>
				)}
				{isReady && (
					<img
						alt="User ready"
						className={styles.ready}
						src={Check}
					/>
				)}
			</div>
			<Typography variant="body2" className={styles.displayName}>
				{displayName}
			</Typography>
			{children}
		</div>
	);
};
