import { Route, useRouteMatch } from 'react-router-dom';
import { CommonRouter } from '@common/routes';
import { Layout } from '../components';
import { routes } from './routes';
import { Home } from '../pages/home';
import { Lobby } from '../pages/lobby';
import { CreateActivity } from '../pages/create-activity';
import { Checkin } from '../pages/activity';
import { CreateScheduledRoom } from '../pages/create-scheduled-room/page';
import { Room } from '../pages/room/page';
import { ProPageRoute } from '@common/routes/user-land-routes/proPageRoute';
import { ScreenContainer } from '@common/components';

export const Router = () => {
	const isActivity = useRouteMatch({
		exact: true,
		path: routes.checkin.path,
	});
	return (
		<Layout variant={isActivity ? 'activity' : 'landing'}>
			<CommonRouter
				public={
					<>
						<Route exact path={routes.home.path}>
							<Home />
						</Route>
					</>
				}
				private={
					<>
						<ProPageRoute exact path={routes.createActivity.path}>
							<CreateActivity />
						</ProPageRoute>

						<ProPageRoute
							exact
							path={routes.createScheduledRoom.path}
						>
							<CreateScheduledRoom />
						</ProPageRoute>

						<Route exact path={routes.room.path}>
							<Room />
						</Route>
						<Route exact path={routes.lobby.path}>
							<Lobby />
						</Route>
						<Route exact path={routes.checkin.path}>
							<Checkin />
						</Route>
					</>
				}
				ScreenContainer={ScreenContainer}
			/>
		</Layout>
	);
};
