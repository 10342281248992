import React from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { Form, Formik, FormikProps } from 'formik';
import DateTimeFrequency, {
	DateTimeFrequencySchema,
} from '@common/components/scheduling/Forms/DateTimeFrequency';
import UserSelection, {
	UserSelectionSchema,
} from '@common/components/scheduling/Forms/UserSelection';
import type { Step, Steps } from '@common/components/scheduling/step';
import InvitePeople, {
	InvitePeopleSchema,
} from '@common/components/scheduling/Forms/InvitePeople';
import LuxonAdapter from '@date-io/luxon';
import { useCurrentAccount } from '@common/contexts';
import { useAsyncFunction, useCurrentUser } from '@common/hooks';
import { Confirmation } from '@common/components/scheduling/Forms/Confirmation';
import durationOptions from '@common/data/ScheduleDuration';
import buildRecurrenceOptions from '@common/data/RecurrenceOptions';
import nameSession from '@common/assets/images/scheduling/NameSession.svg';
import imgDateTimeFrequency from '@common/assets/images/scheduling/DateTimeFrequency.svg';
import imgUserSelection from '@common/assets/images/scheduling/UserSelection.svg';
import { DateTime } from 'luxon';
import {
	SessionName,
	SessionNameSchema,
} from '@common/components/scheduling/Forms/sessionName';
import { User } from '@contracts/platform';
import { CreateRoomScheduleParams } from '@contracts/room';
import { currentTimezone } from '@common/components/timeZoneSelect';

const luxon = new LuxonAdapter();

const useStyles = makeStyles((theme) => ({
	scheduler: {
		position: 'relative',
		display: 'grid',
		gridTemplateAreas: `
			'content'
			'illustration'
		`,
		gridTemplateRows: 'auto auto auto',
		gridGap: '2rem',

		[theme.breakpoints.up('md')]: {
			gridTemplateAreas: `
				'illustration content'
			`,
			gridTemplateRows: '1fr 1fr',
			gridTemplateColumns: '1fr 1fr',
		},
	},
	illustrationContainer: {
		gridArea: 'illustration',
		display: 'flex',
		justifyContent: 'center',
		width: '100%',

		[theme.breakpoints.up('md')]: {
			width: 'initial',
		},
	},
	illustration: {
		width: '100%',
		borderRadius: '1rem',
		objectFit: 'scale-down',
	},
	content: {
		gridArea: 'content',
		width: '100%',

		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(2),
		},
	},
	formContainer: {
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
	},
	actions: {
		display: 'flex',
		marginTop: theme.spacing(4),

		[theme.breakpoints.up('md')]: {
			marginTop: 0,
		},
	},
	continue: {
		marginLeft: 'auto',
	},
	previous: {},
}));

type Props = {
	onConfirmCallback: (schedule: CreateRoomScheduleParams) => void;
};

type FormLabelValueInput = {
	label: string;
	value: string;
};

/**
 * @todo these weird types to be replaced when scheduling is overhauled.
 * This type is pulled from @common/components/scheduling
 * to coincide with the form components reused
 * */
type FormValues = {
	team: string;
	startDate: DateTime;
	startTime: DateTime;
	players: string[];
	invitees: string[];
	duration: string;
	isRepeating: boolean;
	schedule: string;
};

type State = {
	isFirstStep: boolean;
	isLastStep: boolean;
	step: Step | null;
	previous: () => void;
	next: () => void;
	submit: (values: FormValues) => void;
	accountMembers: User[];
	buttonContent: JSX.Element;
	durationOptions: FormLabelValueInput[];
	recurrenceOptions: FormLabelValueInput[];
	formikRef?: React.MutableRefObject<FormikProps<FormValues> | null>;
};

type AccountUser = User & { id: string };

const useState = ({ onConfirmCallback }: Props): State => {
	const styles = useStyles();
	const currentUser = useCurrentUser();
	const { currentAccount, getAccountMembers } = useCurrentAccount();

	const [currentStep, setCurrentStep] = React.useState<number>(0);
	const recurrenceOptions = buildRecurrenceOptions();
	const formikRef = React.useRef<FormikProps<FormValues>>(null);

	const [getAccountMembersRequest] = useAsyncFunction<
		(params: { accountId: string }) => Promise<AccountUser[]>,
		AccountUser[]
	>(
		async (params: { accountId: string }) =>
			(await getAccountMembers(params.accountId)).data,
		{
			callOnChange: () => {
				if (!currentAccount?.accountId) {
					return;
				}

				return {
					accountId: currentAccount.accountId,
				};
			},
			initialValue: [],
		},
	);

	const accountMembers: AccountUser[] = getAccountMembersRequest.data.filter(
		(member) => member.id !== currentUser.uid,
	);

	const steps: Steps = React.useMemo(
		() => ({
			0: {
				heading: 'Name your schedule',
				Validation: SessionNameSchema,
				Form: (
					<SessionName
						placeholder="Weekly Check-in"
						helperText="This will appear in your calender, the invites sent and in the room page."
					/>
				),
				Illustration: (
					<img
						className={styles.illustration}
						src={nameSession}
						alt=""
					/>
				),
			},
			1: {
				heading: 'Set your schedule',
				Validation: DateTimeFrequencySchema,
				Form: <DateTimeFrequency durationOptions={durationOptions} />,
				Illustration: (
					<img
						className={styles.illustration}
						src={imgDateTimeFrequency}
						alt=""
					/>
				),
			},
			2: {
				heading: 'Select players from team',
				Validation: UserSelectionSchema,
				Form: <UserSelection users={accountMembers} />,
				Illustration: (
					<img
						className={styles.illustration}
						src={imgUserSelection}
						alt=""
					/>
				),
				skip: accountMembers?.length === 0,
			},
			3: {
				heading: 'Invite people by email',
				Validation: InvitePeopleSchema,
				Form: <InvitePeople />,
				Illustration: (
					<img
						className={styles.illustration}
						src={imgUserSelection}
						alt=""
					/>
				),
			},
			4: {
				heading: 'Confirm your schedule',
				Form: <Confirmation allMembers={accountMembers} />,
			},
		}),
		[accountMembers, styles.illustration],
	);

	const isLastStep = currentStep === Object.keys(steps).length - 1;
	const isFirstStep = currentStep === 0;

	const next = React.useCallback(() => {
		if (isLastStep) {
			return;
		}
		setCurrentStep(currentStep + 1);
	}, [currentStep, isLastStep]);

	const previous = React.useCallback(() => {
		if (isFirstStep) {
			return;
		}
		setCurrentStep(currentStep - 1);
	}, [currentStep, isFirstStep]);

	const submit = React.useCallback(
		async (values: FormValues) => {
			if (!isLastStep) {
				return;
			}

			const {
				team,
				startDate,
				startTime,
				invitees,
				players,
				duration,
				schedule: recurrenceRule,
			} = values;

			const startAtLocal = DateTime.local(
				startDate.year,
				startDate.month,
				startDate.day,
				startTime.hour,
				startTime.minute,
				0,
			);

			const startAtUtc = DateTime.fromISO(
				startAtLocal.toUTC().toString(),
				{
					zone: 'utc',
				},
			);
			const matchingDuration = durationOptions.find(
				(d) => d.value === duration,
			);
			const startAt = startAtUtc.toISO();
			const endAt = startAtUtc
				.plus({
					hours: matchingDuration?.data.hours,
					minutes: matchingDuration?.data.minutes,
				})
				.toISO();
			const timeZone = currentTimezone();

			const schedule: CreateRoomScheduleParams = {
				summary: team,
				startAt,
				endAt,
				timeZone,
				recurrenceRule,
				inviteesByUid: players,
				inviteesByEmail: invitees,
			};

			onConfirmCallback(schedule);
		},
		[isLastStep, onConfirmCallback],
	);

	React.useEffect(() => {
		const formik = formikRef.current;
		if (formik) {
			formik.validateForm();
		}
	}, [currentStep, formikRef]);

	const result = React.useMemo<State>(() => {
		const buttonContent = !isLastStep ? (
			<>Continue</>
		) : (
			<>
				<span>Confirm</span>
				<ArrowForwardIcon fontSize={'inherit'} />
				<span>Send</span>
			</>
		);
		const step = steps[currentStep] ?? null;

		return {
			step,
			isFirstStep,
			isLastStep,
			previous,
			next,
			submit,
			buttonContent,
			accountMembers,
			durationOptions,
			recurrenceOptions,
			formikRef,
		};
	}, [
		steps,
		isFirstStep,
		isLastStep,
		currentStep,
		previous,
		next,
		submit,
		accountMembers,
		recurrenceOptions,
		formikRef,
	]);

	return result;
};

export const Scheduler: React.ComponentType<Props> = ({
	onConfirmCallback,
}) => {
	const styles = useStyles();

	const {
		isFirstStep,
		isLastStep,
		step,
		buttonContent,
		formikRef,
		durationOptions,
		recurrenceOptions,
		next,
		previous,
		submit,
	} = useState({
		onConfirmCallback,
	});

	if (!step) {
		return <></>;
	}

	return (
		<div className={styles.scheduler}>
			<div className={styles.illustrationContainer}>
				{step.Illustration}
			</div>

			<div className={styles.content}>
				{formikRef && (
					<Formik
						innerRef={formikRef}
						initialValues={{
							team: '',
							startDate: luxon.date(),
							startTime: luxon.date(),
							players: [],
							invitees: [],
							duration: durationOptions[1]?.value || '',
							isRepeating: false,
							schedule: recurrenceOptions[0]?.value || '',
						}}
						onSubmit={isLastStep ? submit : next}
						validationSchema={step.Validation}
						validateOnMount={true}
					>
						{(form) => (
							<Form className={styles.formContainer}>
								<div>
									<Typography variant="h4">
										{step.heading}
									</Typography>

									{step.Form}
								</div>
								<div className={styles.actions}>
									{!isFirstStep && (
										<Button
											onClick={previous}
											variant="text"
											className={styles.previous}
										>
											<KeyboardBackspaceIcon />
										</Button>
									)}
									<Button
										className={styles.continue}
										variant="contained"
										color="primary"
										disabled={!form.isValid}
										type="submit"
									>
										{buttonContent}
									</Button>
								</div>
							</Form>
						)}
					</Formik>
				)}
			</div>
		</div>
	);
};
