import React from 'react';
import { ensureExists, useCurrentAccount } from '@common';

export type CheckinStoreState = {
	accountId?: string;
};

const Context = React.createContext<CheckinStoreState | null>(null);

export const CheckInStoreProvider: React.ComponentType = ({ children }) => {
	const { currentAccount } = useCurrentAccount();

	const accountId = currentAccount?.accountId;

	const value: CheckinStoreState = React.useMemo(() => {
		return {
			accountId,
		};
	}, [accountId]);

	return <Context.Provider value={value}>{children}</Context.Provider>;
};

export function useCheckInStore() {
	const value = React.useContext(Context);
	return ensureExists(value);
}
