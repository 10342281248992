import {
	RequestState,
	useBackendFunction,
	useCombineRequestState,
} from '@common';
import { useDocumentPath } from './helpers';
import { CheckinActivity } from '@contracts/checkin';

type State = RequestState<
	{
		activity: CheckinActivity;
	},
	{
		activity: undefined;
	}
>;

type Props = {
	activityId: string;
};

export const useJoinAndGetActivity = ({ activityId }: Props): State => {
	const [joinRequest] = useBackendFunction('checkin-join', {
		callOnChange: () => {
			return {
				activityId,
			};
		},
	});

	const getRequest = useDocumentPath(
		joinRequest.status === 'success' &&
			activityId && {
				path: ['checkin_activities', activityId],
			},
	);

	return useCombineRequestState(
		{
			joinRequest,
			activity: getRequest,
		},
		{ activity: undefined },
	);
};
