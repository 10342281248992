import React from 'react';
import {
	Button,
	Container,
	createStyles,
	makeStyles,
	Typography,
} from '@material-ui/core';
import { DocumentHead } from '@common';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import type { CheckinActivity, CheckinUserState } from '@contracts/checkin';
import { isImageCardType, isWordCardType } from '@contracts/checkin';
import { DateTime } from 'luxon';
import type { UserPresence } from '@contracts/activity-presence';
import type { UsersStatus } from '../../../store/getUsersStatus';
import { useSummary } from './state';
import { env } from '@remote-social/common/src/environment/envConfig';
import { UserCard } from './userCard';
import { Header } from '../header/header';
import { Actions } from './actions';
import { useCrossDomainUrl } from '@common/routes/useCrossDomainUrl';

const useStyles = makeStyles((theme) => {
	return createStyles({
		heading: {
			position: 'relative',
			display: 'grid',
			gridAutoFlow: 'column',
			alignItems: 'center',
		},
		hubLink: {
			position: 'absolute',
		},
		subheading: {
			display: 'grid',
			gridAutoFlow: 'column',
			alignItems: 'center',
			padding: theme.spacing(3, 0),
			borderBottom: '1px solid #C4C4C4',
		},
		dateDetails: {},
		dateRelative: {
			textTransform: 'capitalize',
		},
		actions: { justifySelf: 'end' },
		usersCards: {
			display: 'grid',
			gridTemplateColumns: '1fr',
			justifySelf: 'center',
			placeContent: 'center',
			gap: theme.spacing(8),
			padding: theme.spacing(8, 2),

			[theme.breakpoints.up('sm')]: {
				gridTemplateColumns: 'repeat(2, 1fr)',
				padding: theme.spacing(8, 6),
			},

			[theme.breakpoints.up('md')]: {
				gridTemplateColumns: 'repeat(2, 1fr)',
				gap: theme.spacing(13, 8),
				padding: theme.spacing(10, 0),
			},

			[theme.breakpoints.up('lg')]: {
				gridTemplateColumns: 'repeat(3, 1fr)',
			},
		},
	});
});

type Props = {
	activity: CheckinActivity;
	usersPresence: UserPresence[];
	usersStatus: UsersStatus;
	participantsState: CheckinUserState[];
	currentUserUid: string;
	className?: string;
};

export const Summary: React.ComponentType<Props> = ({
	activity,
	currentUserUid,
	usersPresence,
	usersStatus,
	participantsState,
	className,
}) => {
	const { activityStage, cards, createdAt, roomId, accountId } = activity;
	const { titleVariant, avatarVariant } = useSummary();
	const hubUrl = useCrossDomainUrl({
		boundToOrigin: env().hubUrl,
	});
	const styles = useStyles();

	const titleSize = titleVariant === 'xs' ? 'h3' : 'h2';
	const avatarSize = avatarVariant === 'xs' ? 45 : 60;

	return (
		<Container fixed maxWidth="lg" className={className}>
			<DocumentHead title="Summary" />
			<Header />
			<div className={styles.heading}>
				<Button
					className={styles.hubLink}
					variant="text"
					color="default"
					href={hubUrl}
				>
					<ArrowBackIcon /> Hub
				</Button>
				<Typography align="center" variant={titleSize}>
					Summary
				</Typography>
			</div>
			<div className={styles.subheading}>
				<div className={styles.dateDetails}>
					<Typography variant="h3" className={styles.dateRelative}>
						{DateTime.now()
							.minus(createdAt.seconds / 1000)
							.toRelativeCalendar()}
					</Typography>
					<Typography variant="body1">
						{DateTime.fromMillis(
							createdAt.toMillis(),
						).toLocaleString(DateTime.DATE_HUGE)}
					</Typography>
				</div>
				<div className={styles.actions}>
					<Actions
						participantsState={participantsState}
						currentUserUid={currentUserUid}
						accountId={accountId}
						roomId={roomId}
					/>
				</div>
			</div>
			<div className={styles.usersCards}>
				{participantsState.map((participantState) => {
					const imageCard = cards
						.filter(isImageCardType)
						.find(
							(card) =>
								card.checkinCardId ===
								participantState?.imageCardId,
						);
					const wordCard = cards
						.filter(isWordCardType)
						.find(
							(card) =>
								card.checkinCardId ===
								participantState?.wordCardId,
						);

					const userPresence = usersPresence.find(
						(userPresence) =>
							userPresence.uid === participantState.uid,
					);
					const userStatus = usersStatus[participantState.uid];

					return (
						participantState &&
						userPresence &&
						userStatus && (
							<UserCard
								key={userPresence.uid}
								activityStage={activityStage}
								avatarSize={avatarSize}
								userPresence={userPresence}
								userStatus={userStatus}
								participantState={participantState}
								imageCard={imageCard}
								wordCard={wordCard}
							/>
						)
					);
				})}
			</div>
		</Container>
	);
};
