import React from 'react';
import { createStyles, makeStyles, Typography } from '@material-ui/core';
import ChoosingFirstCard from '../assets/choosing-first-card.svg';
import ChoosingSecondCard from '../assets/choosing-second-card.svg';
import ChosenFirstCard from '../assets/chosen-first-card.svg';
import ChosenSecondCard from '../assets/chosen-second-card.svg';
import type { UserStatus } from '../store/getUsersStatus';
import { UnreachableError } from '@common';

const useStyles = makeStyles((theme) =>
	createStyles({
		status: {
			display: 'none',

			[theme.breakpoints.up('md')]: {
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				alignSelf: 'flex-start',
			},
		},
		img: {
			height: '64px',
		},
		success: {
			color: '#4CAF50',
		},
		pending: { color: 'rgba(0, 0, 0, 0.54)' },
	}),
);

type Props = {
	userStatus: UserStatus;
};

export const UserCardStatus: React.ComponentType<Props> = ({ userStatus }) => {
	const styles = useStyles();

	let userCardStatusInner;
	switch (userStatus) {
		case 'selecting-image-card':
			userCardStatusInner = (
				<>
					<img
						alt="Choosing card"
						className={styles.img}
						src={ChoosingFirstCard}
					/>
					<Typography
						variant={'subtitle2'}
						className={styles.pending}
					>
						Choosing Card
					</Typography>
				</>
			);
			break;
		case 'selected-image-card':
			userCardStatusInner = (
				<>
					<img
						alt="Chosen card"
						className={styles.img}
						src={ChosenFirstCard}
					/>
					<Typography
						variant={'subtitle2'}
						className={styles.success}
					>
						Card Chosen
					</Typography>
				</>
			);
			break;
		case 'selecting-word-card':
			userCardStatusInner = (
				<>
					<img
						alt="Choosing card"
						className={styles.img}
						src={ChoosingSecondCard}
					/>
					<Typography
						variant={'subtitle2'}
						className={styles.pending}
					>
						Choosing Card
					</Typography>
				</>
			);
			break;
		case 'selected-word-card':
			userCardStatusInner = (
				<>
					<img
						alt="Chosen card"
						className={styles.img}
						src={ChosenSecondCard}
					/>
					<Typography
						variant={'subtitle2'}
						className={styles.success}
					>
						Card Chosen
					</Typography>
				</>
			);
			break;
		case 'pending':
		case 'spectating':
		case 'presenting-presenter':
		case 'presenting-listener':
			return null;
		default:
			throw new UnreachableError(userStatus);
	}

	return <div className={styles.status}>{userCardStatusInner}</div>;
};
