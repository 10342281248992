import React from 'react';
import { Snackbar } from '@material-ui/core';
import { CheckinActivityStage, CheckinUserState } from '@contracts/checkin';
import { UserPresence } from '@contracts/activity-presence';
import {
	ensureExists,
	ErrorType,
	isError,
	isPending,
	LoadingButton,
	useBackendFunction,
} from '@common';

type Props = {
	activityId: string;
	activityStage: CheckinActivityStage;
	participantsState: CheckinUserState[];
	usersPresence: UserPresence[];
};

type State = {
	isAbleToClaimHost: boolean;
	onClaimHost: () => void;
	pending: boolean;
	error?: ErrorType;
};

const useState = ({
	activityId,
	activityStage,
	participantsState,
	usersPresence,
}: Props): State => {
	const hostUid = ensureExists(
		participantsState.find((user) => user.role === 'host'),
	).uid;
	const hostUserPresence = ensureExists(
		usersPresence.find((user) => user.uid === hostUid),
	);
	const isHostConnected = hostUserPresence.connected;
	const [isHostInactive, setIsHostInactive] = React.useState(false);
	const isAbleToClaimHost = isHostInactive && activityStage !== 'summary';

	const [claimHost, claimHostRequest] =
		useBackendFunction('checkin-claimHost');

	const pending = isPending(claimHost) || false;
	const error = isError(claimHost) ? claimHost.error : undefined;

	const onClaimHost = React.useCallback(() => {
		if (activityId && isAbleToClaimHost) {
			claimHostRequest({
				activityId,
			});
		}
	}, [activityId, claimHostRequest, isAbleToClaimHost]);

	React.useEffect(() => {
		if (isHostConnected && isHostInactive) {
			setIsHostInactive(false);
		}
	}, [isHostConnected, isHostInactive]);

	React.useEffect(() => {
		const interval = setInterval(() => {
			const isHostInactiveCurrent = hostUserPresence.updatedAt
				? Date.now() - (hostUserPresence.updatedAt as number) > 30000 &&
				  !isHostConnected
				: false;

			isHostInactiveCurrent !== isHostInactive &&
				setIsHostInactive(isHostInactiveCurrent);
		}, 5000);

		return () => clearInterval(interval);
	}, [
		hostUid,
		hostUserPresence.updatedAt,
		isHostConnected,
		isHostInactive,
		usersPresence,
	]);

	const result = React.useMemo(
		() => ({ pending, error, isAbleToClaimHost, onClaimHost }),
		[error, isAbleToClaimHost, onClaimHost, pending],
	);

	return result;
};

export const ClaimHost: React.ComponentType<Props> = ({
	activityId,
	activityStage,
	participantsState,
	usersPresence,
}) => {
	const { pending, error, isAbleToClaimHost, onClaimHost } = useState({
		activityId,
		activityStage,
		participantsState,
		usersPresence,
	});

	if (error) {
		return (
			<Snackbar
				color="primary"
				open={Boolean(error)}
				message="There was an error trying to claim host"
				key={'claimHostError'}
			/>
		);
	}

	return (
		<Snackbar
			color="primary"
			open={isAbleToClaimHost}
			message="The host is inactive, would you like to claim the host role?"
			key={'claimHost'}
			action={
				<LoadingButton
					disabled={pending}
					loading={pending}
					variant="text"
					color="primary"
					onClick={onClaimHost}
				>
					Claim Host
				</LoadingButton>
			}
		/>
	);
};
