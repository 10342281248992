import { createStyles, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import MadeEasy1 from '../../assets/made-easy-1.png';
import MadeEasy2 from '../../assets/made-easy-2.png';
import MadeEasy3 from '../../assets/made-easy-3.png';

const useStyles = makeStyles((theme) =>
	createStyles({
		madeEasy: {
			display: 'grid',
		},
		title: {
			padding: theme.spacing(2, 0),
			textAlign: 'center',

			[theme.breakpoints.up('md')]: {
				padding: theme.spacing(4, 0, 8),
			},
		},
		points: {
			display: 'grid',
			gap: theme.spacing(6),

			[theme.breakpoints.up('md')]: {
				gridTemplateColumns: 'repeat(3, 1fr)',
			},
		},
		pointImg: {
			width: '100%',
			height: 'auto',
			objectFit: 'contain',
		},
		cta: {
			alignSelf: 'center',
			justifySelf: 'center',
			padding: theme.spacing(2, 4),
		},
	}),
);

export const MadeEasy: React.ComponentType = () => {
	const styles = useStyles();

	return (
		<div className={styles.madeEasy}>
			<Typography variant="h2" className={styles.title}>
				Team check-ins made easy
			</Typography>
			<div className={styles.points}>
				<div>
					<img
						src={MadeEasy1}
						alt="Remote Social Check-in How-to"
						className={styles.pointImg}
					/>

					<Typography variant="subtitle1">
						Get a better insight than &quot;Yeah, I am good&quot;
					</Typography>
					<Typography variant="body1">
						Each member of the team selects cards that help them
						frame meaningful stories about their current state,
						providing greater insight to everyone in the team into
						how they are <em>really</em> doing.
					</Typography>
				</div>
				<div>
					<img
						src={MadeEasy2}
						alt="Remote Social Check-in How-to"
						className={styles.pointImg}
					/>
					<Typography variant="subtitle1">
						Breaking down barriers and opening doors
					</Typography>
					<Typography variant="body1">
						Promote an open space for all team members. Give
						everyone a chance to speak and listen to what is
						happening in their work and daily lives.
					</Typography>
				</div>
				<div>
					<img
						src={MadeEasy3}
						alt="Remote Social Check-in How-to"
						className={styles.pointImg}
					/>

					<Typography variant="subtitle1">
						Encourage empathy and peer bonding
					</Typography>
					<Typography variant="body1">
						Sharing is caring, when people share they have more
						empathy, helping your team form stronger bonds.
					</Typography>
				</div>
			</div>
		</div>
	);
};
