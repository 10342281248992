import { createStyles, makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { NobleCard } from '../../../components';
import ChooseCards from '../../../assets/choose-cards.svg';
import clsx from 'clsx';
import type { CheckinImageCard, CheckinWordCard } from '@contracts/checkin';
import type { UserStatus } from '../../../store/getUsersStatus';
import { useDeck } from './state';
import { usersWidthLg } from '../users/users';

export const deckHeightLg = '224px';
export const deckWidthLg = '306px';

const useStyles = makeStyles((theme) => {
	return createStyles({
		deck: {
			display: 'grid',
			alignItems: 'center',
			justifyContent: 'center',
			width: '100%',
			height: '168px',
			padding: theme.spacing(2, 0),
			backgroundColor: '#F5F5F5',
			boxShadow: '0px -4px 8px -1px rgba(0, 0, 0, 0.25)',

			[theme.breakpoints.up('md')]: {
				width: deckWidthLg,
				height: deckHeightLg,
				borderTopRightRadius: theme.spacing(2),
				// ensures width of main content hits the edge
				marginRight: `calc(-${deckWidthLg} - -${usersWidthLg})`,
			},
		},
		chooseCards: {
			width: '144px',
			height: 'auto',
		},
		cards: {
			position: 'relative',
			display: 'flex',
			flexDirection: 'row-reverse',
			alignItems: 'center',
			justifyContent: 'center',

			[theme.breakpoints.up('md')]: {
				flexDirection: 'row',
				width: theme.spacing(24),
				height: theme.spacing(18),
			},
		},
		card: {
			maxWidth: '176px',
			height: 'auto',
			margin: theme.spacing(1, 2),

			[theme.breakpoints.up('md')]: {
				position: 'absolute',
			},
		},
		wordCard: {
			[theme.breakpoints.up('md')]: {
				right: '-15%',
				bottom: '-10%',
				transform: 'rotate(1.81deg)',
			},
		},
		imageCard: {
			[theme.breakpoints.up('md')]: {
				left: '-15%',
				top: '-10%',
				transform: 'rotate(-6.85deg)',
			},
		},
		singleCard: {
			[theme.breakpoints.up('md')]: {
				position: 'relative',
				left: 0,
				top: 0,
				transform: 'none',
			},
		},
		text: {
			[theme.breakpoints.up('md')]: {
				alignSelf: 'flex-end',
			},
		},
	});
});

type Props = {
	userStatus: UserStatus;
	selectedImageCard?: CheckinImageCard;
	selectedWordCard?: CheckinWordCard;
	className?: string;
};

export const Deck: React.ComponentType<Props> = ({
	userStatus,
	selectedImageCard,
	selectedWordCard,
	className,
}) => {
	const { statusText } = useDeck({ userStatus });
	const styles = useStyles();

	return (
		<div className={clsx(styles.deck, className)}>
			<div className={styles.cards}>
				{!selectedImageCard && !selectedWordCard && (
					<img
						className={styles.chooseCards}
						alt="Choose cards"
						src={ChooseCards}
					/>
				)}
				{selectedWordCard && (
					<NobleCard
						key={selectedWordCard.checkinCardId}
						className={clsx(styles.card, styles.wordCard)}
						card={selectedWordCard}
						textAlignment="right-bottom"
						variant="sm"
					/>
				)}
				{selectedImageCard && (
					<NobleCard
						key={selectedImageCard.checkinCardId}
						className={clsx(styles.card, styles.imageCard, {
							[styles.singleCard]: !selectedWordCard,
						})}
						card={selectedImageCard}
					/>
				)}
			</div>
			<Typography
				variant="subtitle1"
				align="center"
				className={styles.text}
			>
				{statusText}
			</Typography>
		</div>
	);
};
