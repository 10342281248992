import { createStyles, makeStyles, Typography } from '@material-ui/core';
import ToolkitLandingHero from '../../assets/toolkit-landing-hero.png';
import React from 'react';
import { Actions } from './actions';
import { ProChip } from '@common/components/proChip';

const useStyles = makeStyles((theme) =>
	createStyles({
		hero: {
			display: 'grid',
			gap: theme.spacing(6),
			padding: theme.spacing(4, 0),

			[theme.breakpoints.up('md')]: {
				gridAutoFlow: 'column',
				gridTemplateColumns: 'minmax(45%, 1fr)',
				padding: theme.spacing(8, 0),
			},
		},
		primary: {
			display: 'flex',

			[theme.breakpoints.up('md')]: {
				gridColumn: 2,
			},
		},
		heroImg: {
			width: '100%',
			height: 'auto',
			objectFit: 'contain',
		},
		secondary: {
			[theme.breakpoints.up('md')]: {
				gridColumn: 1,
			},
		},
		title: {
			padding: theme.spacing(1, 0),
		},
		subtitle: {
			padding: theme.spacing(1, 0),
		},
		introduction: {
			padding: theme.spacing(1, 0),
		},
		cta: {
			padding: theme.spacing(5, 0),
		},
	}),
);

export const Hero: React.ComponentType = () => {
	const styles = useStyles();

	return (
		<div className={styles.hero}>
			<div className={styles.primary}>
				<img
					src={ToolkitLandingHero}
					alt="Remote Social Check In"
					className={styles.heroImg}
				/>
			</div>
			<div className={styles.secondary}>
				<Typography variant="h2" className={styles.title}>
					Check-in <ProChip />
				</Typography>
				<Typography variant="h3" className={styles.subtitle}>
					Check-in with your team using a fun game-like interactive
					tool.
				</Typography>
				<Typography variant="body1" className={styles.introduction}>
					Spark interesting stories within your team, while boosting
					alignment. Check-in is a tool backed by neuroscience to
					build rapport, check-in weekly with your team and humanise
					your meetings.
				</Typography>
				<div className={styles.cta}>
					<Actions />
				</div>
			</div>
		</div>
	);
};
