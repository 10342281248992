import React from 'react';
import { useFirebase, useFirebaseConnect } from 'react-redux-firebase';
import type {
	ActivityPresence,
	UserPresence,
} from '@contracts/activity-presence';
import { useSelector } from 'react-redux';
import { useCurrentUser } from '@common';
import { useParams } from 'react-router-dom';
import firebase from 'firebase';
import TIMESTAMP = firebase.database.ServerValue.TIMESTAMP;

type RtCheckinActivityPresence = {
	firebase: {
		data: {
			activityPresence: Record<string, ActivityPresence>;
		};
	};
};

type State = {
	usersPresence: UserPresence[];
};

type Params = {
	activityId: string;
};

export const useActivityPresence = (): State => {
	const { activityId } = useParams<Params>();
	const { uid: currentUserUid } = useCurrentUser();
	const firebase = useFirebase();

	useFirebaseConnect(
		activityId && [
			{
				path: `activityPresence/${activityId}`,
			},
		],
	);

	/** @todo find out better way to do this */
	const currentActivityPresence = useSelector<
		RtCheckinActivityPresence,
		ActivityPresence | undefined
	>(
		({
			firebase: {
				data: { activityPresence },
			},
		}) => activityPresence?.[activityId ?? ''],
	);

	const usersById = currentActivityPresence?.users;
	const usersPresence = Object.values(usersById || {});
	usersPresence.sort((a, b) => a.createdAt - b.createdAt);

	React.useEffect(() => {
		if (currentUserUid) {
			const playerRef = firebase
				.database()
				.ref(`/activityPresence/${activityId}/users/${currentUserUid}`);

			playerRef.update({
				connected: true,
				updatedAt: TIMESTAMP,
			});
			playerRef.onDisconnect().update({
				connected: false,
				updatedAt: TIMESTAMP,
			});
		}
	}, [firebase, activityId, currentUserUid]);

	const result: State = React.useMemo(() => {
		return {
			usersPresence,
		};
	}, [usersPresence]);

	return result;
};
