import React from 'react';
import { LinkButton } from '@common';
import { routes } from '../../routes';
import { createStyles, makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router';

const useStyles = makeStyles((theme) =>
	createStyles({
		actions: {
			display: 'flex',
			flexDirection: 'column',
		},
		cta: {
			'&:first-child': {
				margin: `0 0 ${theme.spacing(2)} 0`,
			},
		},
	}),
);

export const Actions: React.ComponentType = () => {
	const styles = useStyles();
	const location = useLocation();
	const createActivityLocation = routes.createActivity({ location });
	const createRoomLocation = routes.createScheduledRoom({ location });

	return (
		<div className={styles.actions}>
			<LinkButton
				variant="contained"
				color="primary"
				to={createRoomLocation}
				size="large"
				className={styles.cta}
			>
				Create recurring check-in
			</LinkButton>
			<LinkButton
				variant="outlined"
				color="primary"
				to={createActivityLocation}
				size="large"
				className={styles.cta}
			>
				Create one-off check-in
			</LinkButton>
		</div>
	);
};
