import { DocumentHead, Loading, PageError } from '@common/components';
import { useCreateRoom } from './state';
import React from 'react';
import { Scheduler } from '../../components/scheduler';
import { Container } from '@material-ui/core';

export const CreateScheduledRoom: React.ComponentType = () => {
	const state = useCreateRoom();

	if (state.status === 'error') {
		return <PageError error={state.error} />;
	}
	if (state.status === 'pending') {
		return <Loading showRandomLabels />;
	}

	return (
		<Container fixed maxWidth="md">
			<DocumentHead title="Create scheduled room" />
			<Scheduler onConfirmCallback={state.onConfirmCallback} />
		</Container>
	);
};
